<template>
  <div class="message">
    <div class="flex" :class="sender ? 'flex-row-reverse' : ''">
      <div class="text w-2/4 m-2 p-4 pr-0" :class="'text-message ' + (sender ? 'form-me' : 'from-customer')">
        <slot/>

        <label class="flex text-label" :class="'flex-row-reverse' ">
          <v-icon color="#fff" v-if="sender" x-small>{{ !isSeen ? 'mdi-check-circle' : 'mdi-checkbox-multiple-marked-circle'}}</v-icon>
          <span class="time-text pr-4">{{ getTimeString() }}</span>
        </label>

      </div>

    </div>
  </div>
</template>
<style scoped>
.text-message {
  border-radius: 10px;
}

.text-label {
  font-size: 10px;
  opacity: 0.8;

}

.img-sender {
  border-radius: 25px;
  width: 30px;
  height: 30px;
  align-self: center;
}

.form-me {
  background-color: #144887;
  color: #fff;
}

.from-customer {
  background-color: #91c7d0;

}
</style>
<script>

export default {
  components: {},
  props: {
    name: {type: String, default: ''},
    time: {type: Number, default: 0},
    photoUrl: {type: String, default: ''},
    sender: {type: Boolean, default: false},
    isSeen: {type: Boolean}
  },
  methods: {
    getTimeString() {
      const date = new Date(this.time)

      return date.toLocaleString()

    }
  },
  mounted() {

  }
}
</script>

<template>
    <v-card width="100%">
      <v-card-title class="title">Chat with Customers</v-card-title>
      <Chat/>
    </v-card>


</template>
<style scoped>
.title{
  background-color: #144887;
  color: white;
}
</style>
<script>
import Chat from "@/components/Chats/Chat.vue";

export default {
  components: {Chat},
  computed: {
    item() {
      return this.$store.getters.getItem;
    },
    randomColor() {
      const r = () => Math.floor(256 * Math.random());

      return `rgb(${r()}, ${r()}, ${r()})`;
    },
  },
  methods: {
    formatDate(val, on_time) {
      var data = new Date(val);
      var string =
          data.getUTCFullYear() +
          "/" +
          (data.getUTCMonth() + 1) +
          "/" +
          data.getDate();

      if (on_time == null) {
        string +=
            " " +
            data.getHours() +
            ":" +
            data.getMinutes() +
            ":" +
            data.getSeconds();
      }
      return string;
    },

    setForm(val) {
      this.id = val.id;
      let form = {
        send_message: null,
        type_id: null,
        rest: null,
        price_id: null,
        status: null,
        payment_method: null,
        message_template: null,
        customer_id: null,
        country: null,
        character_currency: null,
        currency: null,
        beneficiary_no: null,
        active: null,
      };
      this.$store.dispatch("initForm", form);
      if (val != null) {
        this.isNew = false;
        this.$store.dispatch("setForm", val);
      } else {
        this.isNew = true;
      }
      this.dialog_order_form = true;
    },
  },
  mounted() {
    this.$store.dispatch(
        "fetchItem",
        "customer_profile?id=" + this.$route.params.id
    );
  },
};
</script>

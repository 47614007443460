<template>
  <v-form @submit.prevent="save">
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <Input
                label="amount in currency"
                type="text"
                :model="AmountCurrency == null ? form.amount : AmountCurrency"
                @changeValue="
                  (val) => {
                    form.amount = val;
                  }
                "
              ></Input>
              <v-btn color="primary" @click="changeAmount(form.amount)">
                to SYP
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <Input
                label="amount in sy"
                type="text"
                :model="AmountSY == null ? form.amount_sy : AmountSY"
                :debounce="true"
                @changeValue="
                  (val) => {
                    form.amount_sy = val;
                  }
                "
              ></Input>
              <v-btn color="primary" @click="changeAmountSY(form.amount_sy)">
                to CURRENCY
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="12">
              <Input
                label="beneficiary number"
                type="text"
                :model="form.beneficiary_no"
                @changeValue="
                  (val) => {
                    form.beneficiary_no = val;
                  }
                "
              ></Input>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <Input
                label="beneficiary name"
                type="text"
                :model="form.beneficiary_name"
                @changeValue="
                  (val) => {
                    form.beneficiary_name = val;
                  }
                "
              ></Input>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <Input
                label="status"
                type="text"
                :model="form.status"
                @changeValue="
                  (val) => {
                    form.status = val;
                  }
                "
              ></Input>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <Input
                label="notes"
                type="text"
                :model="form.notes"
                @changeValue="
                  (val) => {
                    form.notes = val;
                  }
                "
              ></Input>
            </v-col>
            <v-col cols="12" >
              <v-color-picker
                dot-size="10"
                hide-canvas
                hide-sliders
                mode="hexa"
                show-swatches
                v-model="form.color"
                @change="(val) => {
                    form.color = val.hex;
                  }"
              ></v-color-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <div>
          <Button color="blue darken-1" type="submit" label="Save"></Button>
        </div>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    api: Object,
    isNew: Boolean,
    page: {
      default: 1,
    },
    newItemLabel: {
      default: "item",
    },
  },
  computed: {
    ...mapGetters(["getForm"]),
    form() {
      return this.getForm;
    },
    formTitle() {
      return (this.isNew ? " Create " : " Edit ") + this.newItemLabel;
    },
    AmountSY() {
      return this.$store.getters.getAmountSY;
    },
    AmountCurrency() {
      return this.$store.getters.getAmount;
    },
  },

  methods: {
    changeAmount(newValue) {
      var f = this.form;
      if (f.payment_method == "Bank Transfer" || f.payment_method == "wise") {
        this.$store.dispatch("fetchExchangeMoney", {
          amount: newValue,
          payment_method: "wise",
          currenct_currency: f.currency,
          to_currency: "SYP",
        }).amount;
      } else {
        this.$store.dispatch("fetchExchangeMoney", {
          amount: newValue,
          payment_method: "paypal",
          currenct_currency: f.currency,
          to_currency: "SYP",
        }).amount;
      }
    },
    changeAmountSY(newValue) {
      var f = this.form;
      if (f.payment_method == "Bank Transfer" || f.payment_method == "wise") {
        this.$store.dispatch("fetchExchangeMoney", {
          amount: newValue,
          payment_method: "wise",
          currenct_currency: "SYP",
          to_currency: f.currency,
        }).amount_sy;
      } else {
        this.$store.dispatch("fetchExchangeMoney", {
          amount: newValue,
          payment_method: "paypal",
          currenct_currency: "SYP",
          to_currency: f.currency,
        }).amount_sy;
      }
    },
    save() {
      let formdata = new FormData();
      for (let f in this.form) {
        formdata.append(f, this.form[f]);
      }
      if (!this.isNew) {
        formdata.append("_method", "PUT");
      }
      this.$store.dispatch("sendForm", {
        api: this.api,
        form: formdata,
        isNew: this.isNew,
        page: this.page,
      });
      this.$emit("dialogForm", false);
    },
  },
};
</script>

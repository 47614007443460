<template>


    <v-container fluid class="icons-page">
        <v-container>
            <v-row>
                <v-col>
                    <form>
                        <v-container fluid class="icons-page">

                            <v-card max-width="500px" class="pa-6 mx-auto my-5 ">
                                <Title title="Create New Payment" class="text-center"></Title>
                                <v-row class="pa-10">
                                    <Input
                                            label="payment name"
                                            @changeValue="(val) => { form.name = val }"
                                            attr="name"

                                            required
                                    ></Input>
                                    <v-btn block class="mr-4" color="primary" @click="match">
                                        Create
                                    </v-btn>
                                </v-row>
                            </v-card>
                        </v-container>
                    </form>
                </v-col>
            </v-row>
        </v-container>

        <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
            <v-col>
                <!-- List -->
                <Table
                        newItemLabel="All Customers"
                        :filter="filter"
                        :title="title"
                        :api="api"
                        :create="create"
                        :del="del"
                        :edit="edit"
                        :headers="headers"
                        @openForm="setForm"
                        @RowClick="rowClick"

                ></Table>
                <!-- Form -->

            </v-col>
        </v-row>

    </v-container>

</template>

<script>
    export default {
        data() {
            return {
                isNew: true,
                dialog_form: false,
                form: {
                    name: ""
                },
                api: {
                    filter :'payment_methods',
                    getAll: "payment_methods",
                    create: "create_payment_method",
                    delete: "candidate?candidate_id",
                },
                create: false,
                del: false,
                edit: false,
                filter: "wallets",
                title: "All wallets",
                fields: [
                    {
                        name: "value",
                        key: "value",
                    },

                ],
                headers: [
                    {
                        text: "#",
                        align: "start",
                        sortable: true,
                        value: "id",
                    },
                    {
                        text: "name",
                        value: "name",
                    },
                    {
                        text: "created at",
                        value: "created_at",
                    }
                ],
            };
        },
        methods: {
            rowClick(val) {
                this.$router.push('/payment_method/'+val.item.id)
            },
            setForm(val) {
                let form = {
                    value: null,
                };
                this.$store.dispatch("initForm", form);
                if (val != null) {
                    this.isNew = false;
                    this.$store.dispatch("setForm", val);
                } else {
                    this.isNew = true;
                }
                this.dialog_form = true;
            },
            match() {
                let formdata = new FormData();
                for (let f in this.form) {
                    formdata.append(f, this.form[f]);
                }

                this.$store.dispatch("sendForm", {
                    api: this.api,
                    form: formdata,
                    isNew: true,
                });
            },
        },

    };
</script>

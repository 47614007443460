<template>
    <v-container fluid class="icons-page">
        <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
            <v-col>
                <!-- List -->
                <Table
                        newItemLabel="PayPal Accounts"
                        :filter="filter"
                        :title="title"
                        :api="api"
                        :create="create"
                        :del="del"
                        :edit="edit"
                        :headers="headers"
                        @openForm="setForm"
                ></Table>
                <!-- Form -->

            </v-col>
        </v-row>
        <v-dialog v-model="dialog_form" max-width="800px">
      <CreatePayPalAccount
        v-if="dialog_form"
        newItemLabel="PayPal Account"
        :isNew="isNew"
        :api="api"
        @dialogForm="dialog_form = false"
      ></CreatePayPalAccount>
    </v-dialog>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                isNew: true,
                dialog_form: false,
                api: {
                    getAll: "paypal_accounts",
                    create: "paypal_account",
                    edit: "paypal_account_edit",
                    delete: "paypal_account_delete?id",
                },
                create: true,
                del: true,
                edit: true,
                filter: "paypal",
                title: "paypal Accounts",
            
                headers: [
                    {
                        text: "#",
                        align: "start",
                        sortable: true,
                        value: "id",
                    },

                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                    },
                    {
                        text: "paypal name",
                        value: "paypal_name",
                    },

                    {
                        text: "currency",
                        value: "currency_tag",
                    },
                    {
                        text: "EndID",
                        value: "EndID_tag",
                    },
                    {
                        text: "type",
                        value: "type_tag",
                    },
                    {
                        text: "secret",
                        value: "secret",
                    },
                    {
                        text: "client",
                        value: "client",
                    },

                ],
            };
        },
        methods: {
            setForm(val) {
                console.log(val);
                let form = {
                    id: null,
                    currency: null,
                    EndID: null,
                    type: null,
                    client: null,
                    secret: null,
                    paypal_name: null,
                };
                this.$store.dispatch("initForm", form);
                if (val != null) {
                    this.isNew = false;
                    this.$store.dispatch("setForm", val);
                } else {
                    this.isNew = true;
                }
                this.dialog_form = true;
            },
        },
        //  mounted() {
        //   this.$store.dispatch('initForm', this.form)
        //  }
    };
</script>
  
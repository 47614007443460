<template>
  <v-form @submit.prevent="save">
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <Input
                disabled
                label="Beneficiary Number Now"
                type="text"
                :model="form.money.beneficiary_no"
              ></Input>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <Input
                disabled
                label="Beneficiary Name Now"
                type="text"
                :model="form.money.beneficiary_name"
              ></Input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <Input
                label="Beneficiary Number New"
                type="text"
                :model="form.beneficiary_no"
                @changeValue="
                  (val) => {
                    form.beneficiary_no = val;
                  }
                "
              ></Input>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <Input
                label="Beneficiary Name New"
                type="text"
                :model="form.beneficiary_name"
                @changeValue="
                  (val) => {
                    form.beneficiary_name = val;
                  }
                "
              ></Input>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <TextArea
                label="bot"
                type="text"
                :model="message_to_bot"
              ></TextArea>
              <v-btn
                type="button"
                color="primary"
                v-clipboard:copy="message_to_bot"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                copy
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <div>
          <Button color="blue darken-1" type="submit" label="Save"></Button>
        </div>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import TextArea from "../Form Components/TextArea.vue";

export default {
  props: {
    api: Object,
    isNew: Boolean,
    page: {
      default: 1,
    },
    newItemLabel: {
      default: "Order Update",
    },
  },
  computed: {
    ...mapGetters(["getForm"]),
    form() {
      return this.getForm;
    },
    formTitle() {
      return (this.isNew ? "Create " : "") + this.newItemLabel;
    },
    message_to_bot() {
      if (this.form.beneficiary_no != this.form.money.beneficiary_no) {
        return (
          "يرجى تعديل الطلب لرقم الأشعار \n " +
          this.form.money.notes +
          "\n" +
          "الاسم القديم: " +
          this.form.beneficiary_name +
          " \n" +
          "الاسم الجديد: " +
          this.form.money.beneficiary_name +
          " \n" +
          "الرقم الجديد: " +
          this.form.money.beneficiary_no +
          "\n" +
          "الرقم القديم: " +
          this.form.beneficiary_no +
          " \n"
        );
      }
      return (
        "يرجى تعديل الطلب لرقم الأشعار \n " +
        this.form.money.notes +
        "\n" +
        "الاسم القديم: " +
        this.form.money.beneficiary_name +
        " \n" +
        "الاسم الجديد: " +
        this.form.beneficiary_name +
        " \n"
      );
    },
  },

  methods: {
    onCopy: function (e) {
      this.$toast.success("You just copied the  text to the clipboard");
    },
    onError: function (e) {
      this.$toast.error("Failed to copy the text to the clipboard");

      console.log(e);
    },
    save() {
      let formdata = new FormData();
      for (let f in this.form) {
        formdata.append(f, this.form[f]);
      }
      if (!this.isNew) {
        formdata.append("_method", "PUT");
      }
      this.$store.dispatch("sendForm", {
        api: this.api,
        form: formdata,
        isNew: this.isNew,
        page: this.page,
      });
      this.$emit("dialogForm", false);
    },
  },
};
</script>

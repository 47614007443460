<template>
  <v-container fluid class="icons-page">
    <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
      <v-col>
        <!-- List -->
        <Table
          newItemLabel="All Bills"
          :filter="filter"
          :title="title"
          :api="api"
          :create="create"
          :del="del"
          :edit="edit"
          :pay="pay"
          :block="block"
          :headers="headers"
          @openForm="setForm"
          @openFormPay="setFormPay"
          @update_page="
            (val) => {
              page = val;
            }
          "
        ></Table>
        <!-- Form -->
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_pay_form" max-width="450px">
      <ConfirmPayForm
        v-if="dialog_pay_form"
        newItemLabel="Confirm Paying"
        :isNew="isNew"
        :api="api"
        :page="page"
        @dialogForm="dialog_pay_form = false"
      ></ConfirmPayForm>
    </v-dialog>

    <v-dialog v-model="dialog_form" max-width="500px">
      <GeneralForm
        v-if="dialog_form"
        :fields="fields"
        newItemLabel="Add Notes"
        :isNew="isNew"
        :api="api"
        @dialogForm="dialog_form = false"
      ></GeneralForm>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isNew: true,
      dialog_form: false,
      dialog_pay_form: false,
      page: 1,
      api: {
        getAll: "show-bills-deleted",
        filter: "show-bills-deleted",
        pay: "bills/make-payed",
        edit: "bills/add-notes",
        block: "block_order",
        type:"BILL",
      },
      fields: [
        {
          name: "notes",
          key: "notes",
        },
      ],
      pay: true,
      create: false,
      del: false,
      edit: true,
      filter: "bill",
      title: "all bills",
      block:true,
      headers: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
        {
          text: "customer id",
          value: "customer_id",
        },
        {
          text: "telephone",
          value: "telephone",
        },
        {
          text: "response",
          value: "response_relation.result",
        },
        {
          text: "status",
          value: "status",
        },
        {
          text: "PayPal Account",
          value: "paypal_name",
        },{
          text: "payment method",
          value: "payment_method",
        },
        {
          text: "notes",
          value: "notes",
        },
        {
          text: "provider",
          value: "provider.name_en",
        },
        {
          text: "service",
          value: "service.name_en",
        },
        {
          text: "type",
          value: "children.name_en",
        },
        {
          text: "date",
          value: "created_at",
        },
      ],
    };
  },
  methods: {
    setForm(val) {
      console.log(val);
      let form = {
        id: null,
        notes: null,
      };
      this.$store.dispatch("initForm", form);
      if (val != null) {
        this.isNew = false;
        this.$store.dispatch("setForm", val);
      } else {
        this.isNew = true;
      }
      this.dialog_form = true;
    },
    setFormPay(val) {
      console.log(val);
      let form = {
        id: null,
        customer_id: null,
        provider: null,
        telephone: null,
        response: null,
        status: null,
      };
      this.$store.dispatch("initForm", form);
      if (val != null) {
        this.isNew = false;
        this.$store.dispatch("setForm", val);
      } else {
        this.isNew = true;
      }
      this.dialog_pay_form = true;
    },
  },
  //  mounted() {
  //   this.$store.dispatch('initForm', this.form)
  //  }
};
</script>

<template>
  <v-form @submit.prevent="save">
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="12">
              <Input
                label="Code"
                type="text"
                :model="form.value"
                :errorMessages="ValueErrors"
                @changeValue="
                  (val) => {
                    form.value = val;
                    $v.form.value.$touch();
                  }
                "
              ></Input>
            </v-col>
            <v-col cols="12" sm="6" md="12">
              <v-btn
                width="100%"
                depressed
                type="submit"
                dark
                color="blue darken-1"
              >
                Save
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="12">
              <v-btn width="100%" depressed dark color="green" @click="(val)=>{this.form.value= 500; this.save()}">
                500
              </v-btn></v-col
            >
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [validationMixin],
  props: {
    api: Object,
    isNew: Boolean,
    page:{
      default:1
    },
    newItemLabel: {
      default: "عنصر",
    },
  },
  validations: {
    form: {
      value: {
        required,
      },
    },
  },
  data() {
    return {
      value_error_msgs: {
        required: "This Field is Required.",
      },
    };
  },
  computed: {
    ...mapGetters(["getForm"]),
    valueErrors() {
      const errors = [];
      if (!this.$v.form.value.$dirty) return errors;
      !this.$v.form.value.required &&
        errors.push(this.value_error_msgs.required);
      return errors;
    },
    form() {
      return this.getForm;
    },
    formTitle() {
      return (this.isNew ? " Create " : " Edit ") + this.newItemLabel;
    },
  },
  watch: {
    model() {},
  },
  methods: {
    save() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        let formdata = new FormData();
        for (let f in this.form) {
          formdata.append(f, this.form[f]);
        }
        if (!this.isNew) {
          formdata.append("_method", "PUT");
        }
        this.$store.dispatch("sendForm", {
          api: this.api,
          form: formdata,
          isNew: this.isNew,
          page:this.page

        });
        this.$emit("dialogForm", false);
      } else {
        this.$toast.error("Pleace Fill All Fields");
      }
    },
  },
};
</script>

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import Filter from 'bad-words'
import {ref, onUnmounted, computed} from 'vue'
// import axios from "axios";


const app = firebase.initializeApp({
    apiKey: "AIzaSyARLaChOQD8xIM3e2tzHCzvlSflQBHRQ0o",
    authDomain: "wesal-app-1eb6a.firebaseapp.com",
    projectId: "wesal-app-1eb6a",
    storageBucket: "wesal-app-1eb6a.appspot.com",
    messagingSenderId: "602703851082",
    appId: "1:602703851082:web:5516f7973c4858a0ccc0b3",
    measurementId: "G-LQMFDF3KTZ"
})


const auth = firebase.auth()
const filter = new Filter()

const messages = ref([])
let unsubscribe;

export function useAuth() {
    const user = {
        displayName: "admin",
        uid: "admin",
        text: filter.clean("asdasdasdsadasdsa"),
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
    }
    //const unsubscribe = auth.onAuthStateChanged(_user => (user.value = _user))
    //onUnmounted(unsubscribe)
    const isLogin = computed(() => user.value !== null)

    // const signIn = async () => {
    //   const googleProvider = new firebase.auth.GoogleAuthProvider()
    //   await auth.signInWithPopup(googleProvider)
    // }
    const signOut = () => auth.signOut()

    return {user, isLogin, signOut}
}

const firestore = firebase.firestore()

const Users = firestore.collectionGroup('users')
const messagesUserQuery = Users

export function getUserChat() {

    const docRef = firestore.doc('users/yahea@gmail.com/chats/Admin.Admin@gmail.com');
    //console.log("dsadacc", docRef.collection("messages"))

    docRef.collection('messages').get().then((subCollectionSnapshot) => {
        subCollectionSnapshot.forEach((doc) => {
            //console.log("aaa",doc.id, " => ", doc.data());

        });
    });


}

export function getAllUsers(showUsers = ref(true)) {
    const users = ref([]);
    const db = firebase.firestore();

    db.collection('users').onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {

            if (change.type === 'added' || change.type === 'modified') {

                const doc = change.doc;
                const userChats = db.collection('users/' + doc.id + '/chats/Admin.Admin@gmail.com/messages');
                userChats.where('isSeen', '==', false).where('userId', '==', doc.id).onSnapshot((chatSnapshot) => {
                    showUsers.value = false

                    const userIndex = users.value.findIndex(user => user.doc.id === doc.id);
                    const userData = {
                        doc,
                        userId: doc.data().id,
                        unreadCount: chatSnapshot.size, ...doc.data(),
                        show: true
                    };
                    if (userIndex === -1) {
                        // User not found in array, add it
                        users.value.push(userData);
                    } else {
                        // User found in array, update it
                        users.value[userIndex] = userData;
                    }
                    showUsers.value = true

                });
            }

        });
    });
    return users
}

export function createNewChat(email, id, gender, name) {

    const newCollection = firestore.collection('users/').doc(email);
    newCollection.set({
        id: id,
        gender: gender,
        name: name
        // Add more fields as needed
    })

    const aewCollection = firestore.collection('users/' + email + '/chats/Admin.Admin@gmail.com/messages');

}

export function useChat(email, showMessages) {

    messages.value = []

    if (unsubscribe) unsubscribe()

    const {user, isLogin} = useAuth()
    const sendMessage = (text, idNow, fcm_token) => {
        const {uid, displayName} = user
        var ff = firestore.collection('users/' + idNow + '/chats/Admin.Admin@gmail.com/messages')
        ff.add({
            isSeen: false,
            userId: uid,
            message: text,
            messageType: "text",
            time: Date.now()
        })

        const data = {
            //"to": "/topics/all",
            "registration_ids":[fcm_token],
            "priority":"high",
            "data":{
                "order_id": null,
                "order_type":"chat"
            },
            "notification":{
                "title": "Wesal chat",
                "body": text,
                "image":"",
                "orderId": null,
                "type_order": "chat"
            }
        };

        axios.post('https://fcm.googleapis.com/fcm/send', data, {
            headers: {
                'Authorization': 'key=AAAAjFPy7ko:APA91bFV-MTe6HFiQO-ANVGIws5rClNxA4hu8xn7B9b8cEzA2UD6ILbZ8-_sUlWR_VmlXqtty4pB03i3wxtzt-Eo6-vU25VqLMlMQSEF4ZQIVNlR1MMELb5uHs-u9EiZP3RMIcoYeNBb'
            }
        }).then((res) => {
            console.log("ok")
        })

    }
    if (email == '' || email == null || email == undefined) return {messages, sendMessage}

    unsubscribe = firestore.collection('users/' + email + '/chats/Admin.Admin@gmail.com/messages').orderBy('time').onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            showMessages.value = false
            const userIndex = messages.value.findIndex(m => m.id === doc.id);
            const message = {id: doc.id, ...doc.data()};
            if (message.userId !== "admin" && !message.isSeen) {
                doc.ref.update({isSeen: true})
            }
            if (userIndex === -1) {
                // User not found in array, add it
                messages.value.push(message);
            } else {
                // User found in array, update it
                messages.value[userIndex] = message;
            }
            showMessages.value = true

        });
    }, (error) => {
        console.error("Error listening for document updates: ", error);
    });


    return {messages, sendMessage}
}

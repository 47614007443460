<template>
  <v-container fluid class="icons-page">
    <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
      <v-col>
        <form>
          <v-container fluid class="icons-page">
            <v-card max-width="500px" class="pa-6 mx-auto my-5">
              <v-row class="pa-10">
                <v-text-field
                  label="Response Key"
                  :model="form.paypal_key"
                  @change="(val)=> form.paypal_key = val"
                  @input="(val)=> form.paypal_key = val"
                  required
                ></v-text-field>
                <FormSelect
                  :items="items"
                  attr="type"
                  label="Type"
                  :model="form.type"
                  @select="
                    (val) => {
                      form.type = val.value;
                    }
                  "
                >
                </FormSelect>
                <v-btn :loading="loadingData" block class="mr-4" color="primary" @click="search">
                  search
                </v-btn>
              </v-row>
            </v-card>
          </v-container>
        </form>
      </v-col>
    </v-row>
    <v-expansion-panels>
      <v-expansion-panel v-for="(item, i) in Response" :key="i" focusable>
          <v-card v-if="item.hasOwnProperty('enquiry')">
            <v-toolbar color="info" dark>Enquiry ID #{{ item.enquiry.id }} <span class="ml-5"> Response Order #{{ item.id }}</span></v-toolbar>

            <v-card-text>
              <v-row>
                <v-col>
                  <p></p>
                  <p>
                    <b class="my-2">customer_id :</b>
                    <router-link
                      target="_blank"
                      :to="'/customer/' + item.enquiry.customer_id"
                      >{{ item.enquiry.customer_id }}</router-link
                    >
                  </p>
                  <p>
                    <b class="my-2">status :</b>{{ item.enquiry.status }}
                  </p></v-col
                >
                <v-col>
                  <p>
                    <b class="my-2">beneficiary_no :</b
                    >{{ item.enquiry.beneficiary_no }}
                  </p>
                  <p><b class="my-2">amount :</b>{{ item.enquiry.amount }}</p>
                  <p>
                    <b class="my-2">amount_currency :</b
                    >{{ item.enquiry.amount_currency }}
                  </p>
                  <p>
                    <b class="my-2">currency :</b>{{ item.enquiry.currency }}
                  </p></v-col
                >
                <v-col>
                  <p>
                    <b class="my-2">result of bank :</b
                    >{{ item.enquiry.result }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card v-if="item.hasOwnProperty('order')">
            <v-toolbar color="info" dark>UNIT Order ID #{{ item.order.id }} <span class="ml-5"> Response Order #{{ item.id }}</span></v-toolbar>

            <v-card-text>
              <v-row>
                <v-col>
                  <p>
                    <b class="my-2">customer_id :</b>
                    <router-link
                      target="_blank"
                      :to="'/customer/' + item.order.customer_id"
                      >{{ item.order.customer_id }}</router-link
                    >
                  </p>
                  <p>
                    <b class="my-2">status :</b>{{ item.order.status }}
                  </p></v-col
                >
                <v-col>
                  <p>
                    <b class="my-2">beneficiary no :</b
                    >{{ item.order.beneficiary_no }}
                  </p>
                  <p><b class="my-2">payment method :</b>{{ item.order.payment_method }}</p>
                  <p>
                    <b class="my-2">price now :</b
                    >{{ item.order.price_now + ' '+  item.order.currency}}
                  </p>
                  <p>
                  </p></v-col
                >
                <v-col>
                  <p>
                    <b class="my-2">response :</b
                    >{{ item.order.response }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        
          <v-card v-if="item.hasOwnProperty('money')">
            <v-toolbar color="info" dark>Money Order ID #{{ item.money.id }} <span class="ml-5"> Response Order #{{ item.id }}</span></v-toolbar>

            <v-card-text>
              <v-row>
                <v-col>
                  <p>
                    <b class="my-2">customer_id :</b>
                    <router-link
                      target="_blank"
                      :to="'/customer/' + item.money.customer_id"
                      >{{ item.money.customer_id }}</router-link
                    >
                  </p>
                  <p>
                    <b class="my-2">status :</b>{{ item.money.status }}
                  </p>
                  <p>
                    <b class="my-2">amount in syria :</b>{{ item.money.amount_sy }}
                  </p>
                  <p>
                    <b class="my-2">amount :</b>{{ item.money.amount + ' '+  item.money.currency}}
                  </p></v-col
                >
                <v-col>
                  <p>
                    <b class="my-2">beneficiary no :</b
                    >{{ item.money.beneficiary_no }}
                  </p>
                  <p>
                    <b class="my-2">beneficiary name :</b
                    >{{ item.money.beneficiary_name }}
                  </p>
                  <p><b class="my-2">payment method :</b>{{ item.money.payment_method }}</p>
                 
                  </v-col
                >
                <v-col>
                  <p>
                    <b class="my-2">response :</b
                    >{{ item.money.response }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card v-if="item.hasOwnProperty('bill')">
            <v-toolbar color="info" dark>Bill Order ID #{{ item.bill.id }} <span class="ml-5"> Response Order #{{ item.id }}</span></v-toolbar>

            <v-card-text>
              <v-row>
                <v-col>
                  <p>
                    <b class="my-2">customer_id :</b>
                    <router-link
                      target="_blank"
                      :to="'/customer/' + item.bill.customer_id"
                      >{{ item.bill.customer_id }}</router-link
                    >
                  </p>
                  <p>
                    <b class="my-2">status :</b>{{ item.bill.status }}
                  </p></v-col
                >
                <v-col>
                  <p>
                    <b class="my-2">beneficiary no :</b
                    >{{ item.bill.beneficiary_no }}
                  </p>
                  <p><b class="my-2">payment name :</b>{{ item.bill.paypal_name }}</p>
                  <p>
                    <b class="my-2">currency :</b
                    >{{   item.bill.currency}}
                  </p>
                  <p>
                  </p></v-col
                >
                <v-col>
                  <p>
                    <b class="my-2">response :</b
                    >{{ item.bill.response }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      items: [
        { text: "UNIT", value: "UNIT" },
        { text: "MONEY", value: "MONEY" },
        { text: "BILL", value: "BILL" },
        { text: "BANK", value: "BANK" },
      ],
      form: {
        type: "UNIT",
        paypal_key: null,
      },
    };
  },
  computed: {
    ...mapGetters(["getResponseData",'getLoadingData']),
    Response() {
      return this.getResponseData;
    },
    loadingData(){
      return this.getLoadingData;
    }
  },
  methods: {
    ...mapActions(["fetchSearchResponse"]),
    search() {
      console.log(this.fetchSearchResponse(this.form));
    },
  },
  mounted() {
    this.$store.dispatch("initForm", this.form);
  },
};
</script>

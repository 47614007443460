import Vue from 'vue'
import App from './App.vue'
import router from './Routes'
import store from './store/index'
import vuetify from './plugins/vuetify'
import components from "./components";
import filter from './filter'
import VueClipboard from 'vue-clipboard2'

import Toast from "vue-toastification";
import axios from "./plugins/axios";
import "vue-toastification/dist/index.css";
import debounce from 'v-debounce'


Vue.use(Toast)
Vue.use(debounce)

VueClipboard.config.autoSetContainer = true // add this line

Vue.use(VueClipboard)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  axios,
  components,
  filter,
  render: h => h(App), store
}).$mount('#app')

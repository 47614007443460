<template>
    <v-container fluid class="icons-page">
        <v-row>
            <v-col>

                <form @submit="match">
                    <v-container fluid class="icons-page">

                        <v-card class="pa-6 mx-auto my-5 ">
                            <Title title="Create New Tax" class="text-center"></Title>
                            <v-row class="pa-10">
                                <v-col class="col-6">
                                    <Input
                                            label="Tax Name"
                                            :model="form.name"
                                            @changeValue="(val) => { form.name = val }"
                                            attr="name"
                                            required
                                    ></Input>
                                </v-col>
                                <v-col class="col-6">
                                    <Input
                                            label="Description"
                                            :model="form.description"
                                            @changeValue="(val) => { form.description = val }"
                                            attr="description"
                                    ></Input>
                                </v-col>
                            </v-row>

                            <v-row class="pa-10">
                                <v-col class="col-6 mt-4">
                                    <Input
                                            label="amount"
                                            type="number"
                                            :model="form.amount"
                                            @changeValue="(val) => { form.amount = val }"
                                            attr="amount"
                                            required
                                    ></Input>
                                </v-col>

                                <v-col class="col-6">
                                    <v-select
                                            :items="options"
                                            v-model="form.type"
                                            required
                                            @changeValue="(val) => { form.type = val }"

                                            name="field"
                                    >
                                    </v-select>

                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn block class="mr-4" color="primary" type="submit">
                                    Create
                                </v-btn>
                            </v-row>
                        </v-card>
                    </v-container>
                </form>
            </v-col>
        </v-row>
        <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
            <v-col>
                <!-- List -->
                <Table
                        newItemLabel="All Customers"
                        :filter="filter"
                        :title="title"
                        :api="api"
                        :actions="api"
                        :create="create"
                        :del="del"
                        :edit="edit"
                        :headers="headers"
                        @openForm="setForm"

                ></Table>
                <!-- Form -->

            </v-col>
        </v-row>

    </v-container>

</template>

<script>

    import Title from "../../components/Title.vue";
    import {mapGetters, mapActions} from "vuex";


    export default {
        components: {Title},

        data() {

            return {
                isNew: true,
                dialog_form: false,
                optionSelected: 'static',

                options: ["static", "percentage"],

                api: {
                    filter: 'taxes',
                    getAll: "taxes",
                    create: "create_tax",
                    delete: "taxes?tax_id",
                },
                create: false,
                del: true,
                edit: false,
                filter: "taxes",
                title: "All taxes",
                fields: [
                    {
                        name: "value",
                        key: "value",
                    },

                ],
                headers: [
                    {
                        text: "#",
                        align: "start",
                        sortable: true,
                        value: "id",
                    },
                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                    },
                    {
                        text: "name",
                        value: "name",
                    },
                    {
                        text: "amount",
                        value: "amount",
                    },
                    {
                        text: "description",
                        value: "description",
                    },
                    {
                        text: "type",
                        value: "type",
                    },
                    {
                        text: "created at",
                        value: "created_at",
                    }

                ],
            };
        },
        computed: {
            ...mapGetters(["getForm"]),

            form() {
                return this.getForm;
            },
        },
        mounted() {

            let form = {
                name: "",
                description: "",
                amount: "",
                type: "static",
            };
            this.$store.dispatch("initForm", form);
        },

        methods: {

            setForm(val) {
                let form = {
                    value: null,
                };
                this.$store.dispatch("initForm", form);
                if (val != null) {
                    this.isNew = false;
                    this.$store.dispatch("setForm", val);
                } else {
                    this.isNew = true;
                }
                this.dialog_form = true;
            },
            match() {
                let formdata = new FormData();
                for (let f in this.form) {
                    formdata.append(f, this.form[f]);
                }

                this.$store.dispatch("sendForm", {
                    api: this.api,
                    form: formdata,
                    isNew: true,
                });
            },
        },

    };
</script>

import axios from "axios";
const state = () => ({
  customer: [],

});

const actions = {
  async fetchCustomer({ commit }, api_info) {
    await axios
      .get(`${api_info.one}`)
      .then((res) => {
        let resultData = res.data.data;
        commit("SET_MESSAGES", resultData);
      })
      .catch((err) => {
        commit("SET_MESSAGES", err);
      });
    },
      async fetchCategoryMessages({ commit }, api_info) {
        await axios
          .get(`${api_info.one}`)
          .then((res) => {
            let resultData = res.data.data;
            commit("SET_MESSAGES", resultData);
          })
          .catch((err) => {
            commit("SET_MESSAGES", err);
          });
    
        } 

};

const getters = {



};

const mutations = {
  SET_MESSAGES(state, payload) {
    state.MessageCategory = payload;
  },
  
};

export default {
  state,
  getters,
  mutations,
  actions,
};

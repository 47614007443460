<template>
  <v-dialog
    class="rounded-0 bg-white darken-2 "
    v-model="notify"
    max-width="550"
  >
  <div class="pa-4 " style="background-color: white;">

    
      <v-toolbar height="78" :color="color" dark>{{ title }}</v-toolbar>


      <v-card-actions >
        <v-btn color="blue darken-1" text @click="confirmDialog(true)"
          >Yes</v-btn
        >
        <v-btn color="red darken-1" text @click="confirmDialog(false)"
          >Cancel</v-btn
        >
      </v-card-actions>

      <div v-if="this.model">
          
          <template>
          <v-card-text >
            <div v-for="key in Object.keys(this.model)" :key="key" ><b v-if="key != 'bank'">{{key}} : </b><span v-if="key != 'bank'"> {{ model[`${key}`] }}</span></div>
          </v-card-text>
          </template>






        <v-card-text v-if="this.model.beneficiary_no">
          <div><b>beneficiary no :</b>{{ this.model.beneficiary_no }}</div>
        </v-card-text>
        <template v-if="this.model.pricing">
          <v-card-text v-if="this.model.pricing.units">
          <div><b>unite : </b>{{ this.model.pricing.units }}</div>
        </v-card-text>
        </template>
        
        <v-card-text v-if="this.model.customer_id">
          <div class="">
            <b>Customer : </b>
            <router-link
              target="_blank"
              :to="'/customer/' + this.model.customer_id"
              >{{ this.model.customer_id }}</router-link
            >
          </div>
        </v-card-text>
      </div>

      
  </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    isPopup: {
      default: false,
    },
    color: {
      default: "black",
    },
    model: {
      default: [],
    },
    title: {
      default: "",
    },
    description: {
      default: "",
    },
  },
  computed: {
    ...mapGetters(["getForm"]),
    form() {
      return this.getForm;
    },
  },
  data() {
    return {
      notify: false,
    };
  },
  watch: {
    isPopup() {
      this.notify = true;
    },
  },
  methods: {
    confirmDialog(val) {
      if (val) {
        this.$emit("confirmDialog");
      }
      this.notify = false;
    },
  },
};
</script>

import Vue from "vue";
import Router from "vue-router";
import auth from "@/auth-route";
import Layout from "@/components/Layout/Layout";

// Pages
import Dashboard from "@/pages/Dashboard/Dashboard";
import Wallets from "@/pages/Invintory/Wallets";
import Currencies from "@/pages/SystemConstant/Currencies";
import Currency from "@/pages/SystemConstant/currency";
import Wallet from "@/pages/Invintory/Wallet";
import Taxes from "@/pages/Invintory/Taxes";
import PaymentMethods from "@/pages/Invintory/PaymentMethods";
import PaymentMethod from "@/pages/Invintory/PaymentMethod";
import Typography from "@/pages/Typography/Typography";
import Tables from "@/pages/Tables/Basic";
import Notifications from "@/pages/Notifications/Notifications";
import Icons from "@/pages/Icons/Icons";
import Charts from "@/pages/Charts/Charts";
import Error from "@/pages/Error/Error";
import Login from "@/pages/Login/Login";
import AllOrders from "@/pages/Orders/index";
import FailOrders from "@/pages/Orders/fail-order";
import FailFriends from "@/pages/Orders/fail-friends";
import FailRest from "@/pages/Orders/fail-rest";
import AllBills from "@/pages/Bills/bill-index";
import DoneBills from "@/pages/Bills/bill-Done";
import unDoneBills from "@/pages/Bills/bill-unDone";
import AllBanks from "@/pages/Bank/bank-order";
import BankServices from "@/pages/Bank/bank-service";
import CustomersAll from "@/pages/Customers/index";
import Customer from "@/pages/Customers/customer";
import Chat from "@/pages/Customers/chats";
import CouponsAll from "@/pages/Coupons/index";
import SystemConstant from "@/pages/SystemConstant/index";
import Messages from "@/pages/Message/index";
import Cards from "@/pages/Card/index";
import MoneyIndex from "@/pages/Money/index";
import MoneyUpdate from "@/pages/Money/MoneyUpdate";
import MoneyInProgress from "@/pages/Money/inProgress";
import MoneyInProgressPaypal from "@/pages/Money/inProgressPayPal";
import MoneyFailed from "@/pages/Money/Failed";
import MoneyMatching from "@/pages/Money/MoneyMatching";
import MoneyNotMatching from "@/pages/Money/MoneyNotMatching";
import IslamicItem from "@/pages/Bank/item";
import BankVerified from "@/pages/Bank/bank-verified";
import PayPalAccounts from "@/pages/PayPalAccounts/index";
import PayPalResponse from "@/pages/PayPalResponse/index";
import SmsPage from "@/pages/Sms/index";
import OrdersDeleted from "@/pages/Orders/deleted";
import BankDeleted from "@/pages/Bank/deleted";
import BillsDeleted from "@/pages/Bills/deleted";
import MoneyDeleted from "@/pages/Money/deleted";
Vue.use(Router);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,

  },
  {
    path: "/",
    redirect: "login",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: { loginRequired: false },
      },
      {
        path: "taxes",
        name: "taxes",
        component: Taxes,
        meta: { loginRequired: true },
      },
      {
        path: "wallets",
        name: "wallets",
        component: Wallets,
        meta: { loginRequired: true },
      },
      {
        path: "payment_methods",
        name: "payment_methods",
        component: PaymentMethods,
        meta: { loginRequired: true },
      },
      {
        path: "paypal_accounts",
        name: "paypal_accounts",
        component: PayPalAccounts,
        meta: { loginRequired: true },
      },
      {
        path: "paypal_response",
        name: "paypal_response",
        component: PayPalResponse,
        meta: { loginRequired: true },
      },
      {
        path: "MoneyUpdate",
        name: "MoneyUpdate",
        component: MoneyUpdate,
        meta: { loginRequired: true },
      },
      {
        path: "wallet/:id",
        name: "wallet",
        component: Wallet,
        meta: { loginRequired: true },
      },
      {
        path: "currency/:id",
        name: "currency",
        component: Currency,
        meta: { loginRequired: true },
      },
      {
        path: "payment_method/:id",
        name: "payment_method",
        component: PaymentMethod,
        meta: { loginRequired: true },
      },
      {
        path: "typography",
        name: "Typography",
        component: Typography,
        meta: { loginRequired: true },
      },
      {
        path: "tables",
        name: "Tables",
        component: Tables,
        meta: { loginRequired: true },
      },
      {
        path: "notifications",
        name: "Notifications",
        component: Notifications,
        meta: { loginRequired: true },
      },
      {
        path: "icons",
        name: "Icons",
        component: Icons,
        meta: { loginRequired: true },
      },
      {
        path: "charts",
        name: "Charts",
        component: Charts,
        meta: { loginRequired: true },
      },
      {
        path: "orders",
        name: "Orders",
        component: AllOrders,
        meta: { loginRequired: true },
      },
      {
        path: "fail-orders",
        name: "failOrders",
        component: FailOrders,
        meta: { loginRequired: true },
      },
      {
        path: "fail-friends",
        name: "failFriends",
        component: FailFriends,
        meta: { loginRequired: true },
      },

      {
        path: "fail-rest",
        name: "failRest",
        component: FailRest,
        meta: { loginRequired: true },
      },
      {
        path: "bills-all",
        name: "billsAll",
        component: AllBills,
        meta: { loginRequired: true },
      },
      {
        path: "bills-done",
        name: "billsDone",
        component: DoneBills,
        meta: { loginRequired: true },
      },

      {
        path: "bills-unDone",
        name: "billsunDone",
        component: unDoneBills,
        meta: { loginRequired: true },
      },


      {
        path: "customers-all",
        name: "customersAll",
        component: CustomersAll,
        meta: { loginRequired: true },
      },
      {
        path: "customer/:id",
        name: "customer",
        component: Customer,
        meta: { loginRequired: true },
      },
      {
        path: "Chats",
        name: "chats",
        component: Chat,
        meta: { loginRequired: true },
      },

      {
        path: "coupons-all",
        name: "couponsAll",
        component: CouponsAll,
        meta: { loginRequired: true },
      },
      {
        path: "system-constant",
        name: "systemConstant",
        component: SystemConstant,
        meta: { loginRequired: true },
      },
      {
        path: "messages",
        name: "Messages",
        component: Messages,
        meta: { loginRequired: true },
      },
      {
        path: "bank-order",
        name: "bankorder",
        component: AllBanks,
        meta: { loginRequired: true },
      },
      {
        path: "bank-service",
        name: "bankservice",
        component: BankServices,
        meta: { loginRequired: true },
      },
      {
        path: "bank-verified",
        name: "bankverified",
        component: BankVerified,
        meta: { loginRequired: true },
      },
      
      {
        path: "bank-order/:id",
        name: "bankOrderItem",
        component: IslamicItem,
        meta: { loginRequired: true },
      },
      {
        path: "cards",
        name: "cards",
        component: Cards,
        meta: { loginRequired: true },
      },
      {
        path: "money",
        name: "money",
        component: MoneyIndex,
        meta: { loginRequired: true },
      },
      {
        path: "currencies",
        name: "currencies",
        component: Currencies,
        meta: { loginRequired: true },
      },
      {
        path: "MoneyFailed",
        name: "MoneyFailed",
        component: MoneyFailed,
        meta: { loginRequired: true },
      },
      {
        path: "MoneyInProgress",
        name: "MoneyInProgress",
        component: MoneyInProgress,
        meta: { loginRequired: true },
      },
      {
        path: "MoneyInProgressPaypal",
        name: "MoneyInProgressPaypal",
        component: MoneyInProgressPaypal,
        meta: { loginRequired: true },
      },
      {
        path: "MoneyMatching",
        name: "MoneyMatching",
        component: MoneyMatching,
        meta: { loginRequired: true },
      },
      {
        path: "MoneyNotMatching",
        name: "MoneyNotMatching",
        component: MoneyNotMatching,
        meta: { loginRequired: true },
      },
      {
        path: "Sms",
        name: "Sms",
        component: SmsPage,
        meta: { loginRequired: true },
      },
      {
        path: "orders_deleted",
        name: "orders_deleted",
        component: OrdersDeleted,
        meta: { loginRequired: true },
      },
      {
        path: "bank_deleted",
        name: "bank_deleted",
        component: BankDeleted,
        meta: { loginRequired: true },
      },
      {
        path: "bills_deleted",
        name: "bills_deleted",
        component: BillsDeleted,
        meta: { loginRequired: true },
      },
      {
        path: "money_deleted",
        name: "money_deleted",
        component: MoneyDeleted,
        meta: { loginRequired: true },
      },
      
      
    ],
  },
  {
    path: "*",
    name: "Error",
    component: Error,
  },
];

const router = new Router({
  linkActiveClass: "active",
  routes,
});
router.beforeEach(auth);
export default router;

<template>
  <v-list class="v-list-me" :color="checked ? '#e7e7e7' : ''">
    <template>
      <v-list-item
          :key="name"
          @click="$emit('click')"
          link>
        <v-list-item-action class="m-2">
          <v-icon :color="gender === 'male' ? '#006cc4' : '#ef8aef'">{{ gender === "male" ? 'mdi-face-man' : 'mdi-face-woman' }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
              class="grey--text body-2 font-weight-bold"
              link>
            {{id + ': '+name}}
          </v-list-item-title>
        </v-list-item-content>

        <v-badge v-if="countNewMessage !== '0'" class="v-badge" color="red" overlap bordered>
          <template v-slot:badge>
            <span  class=" white--text">{{ countNewMessage }}</span>
          </template>

        </v-badge>
      </v-list-item>
    </template>

  </v-list>

</template>
<style scoped>
.v-badge {
  --v-badge-diameter: 15px; /* Adjust this to change the size of the badge */
}

.v-list-me{
  border-radius: 10px;
  border: 1px solid rgba(110, 110, 110, 0.71);
  margin-top: 7px;
}
.v-list-me:hover{
  background-color: #6e6e6e;
}
</style>
<script>
import Avatar from './Avatar.vue'

export default {
  components: {},
  methods:{

  },
  props: {
    name: {type: String, default: 'Ahmad'},
    checked: {type: Boolean, default: true},
    id: {type: String, default: ''},
    gender: {type: String, default: "female"},
    hasMessage: {type: Boolean, default: false},
    countNewMessage: {type: String,default : "0"}
  },
}
</script>

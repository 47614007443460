<template>
    <v-container fluid class="icons-page">
        <v-card class="mx-auto ">
            <v-container>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-list-item>
                            <v-list-item-avatar size="100">
                                <v-avatar>
                                    <span class="white--text text-h5"><v-icon>mdi-wallet</v-icon></span>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="title">{{item.name}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-list-item class="mx-5">
                            <v-list-item-content>
                                <v-list-item-title style="margin-top:20px;"><b>Amount Now :</b> {{item.code}}
                                </v-list-item-title>
                                <v-list-item-title style="margin-top:5px;"><b>Profits :</b>{{item.symbol}}
                                </v-list-item-title>
                                <v-list-item-title style="margin-top:5px;"><b>Total :</b>{{item.rate_to_sp}}
                                </v-list-item-title>
                                <v-list-item-title style="margin-top:5px;"><b>Created At :</b>{{formatDate(item.created_at)}}
                                </v-list-item-title>
                                <v-list-item-title style="margin-top:5px;"><b>Last Seen :</b>{{formatDate(item.updated_at)}}
                                </v-list-item-title>
                                <v-list-item-title style="margin-top:5px;"><b>id :</b>#{{item.id}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>

            </v-container>
            <v-divider></v-divider>

        </v-card>
        <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2  ">
            <v-col>
                <!-- List -->
                <Table
                        v-if="tab == 0"
                        newItemLabel="All fields"
                        :filter="filter"
                        title="All fields"
                        :api="api"
                        :create="create"
                        :del="del"
                        :edit="edit"
                        :resend="resend"
                        :message="message"
                        :exchange_sim="exchange_sim"
                        :headers="headers"
                        @openForm="setForm"
                        @update_page="(val )=>{page = val}"

                ></Table>
                <!-- Form -->

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                formAddOperation: {
                    id: null,
                    amount: 1,
                    date: null
                },
                isNew: true,
                dialog_order_form: false,
                tab: 0,
                page: 1,
                api: {
                    create: "currency_fields",
                    getAll: "currency_fields/" + this.$route.params.id,
                },
                create: true,
                del: false,
                edit: true,
                resend: true,
                message: true,
                exchange_sim: true,

                filter: "Orders",
                title: "All Customers",

                headers: [
                    {
                        text: "#",
                        align: "start",
                        sortable: true,
                        value: "id",
                    },
                    {
                        text: "",
                        value: "operation",
                        sortable: false,
                    },

                    {
                        text: "title",
                        value: "title",
                    },
                    {
                        text: "value",
                        value: "value",
                    },
                    {
                        text: "payment_name",
                        value: "payment_name",
                    },
                    {
                        text: "created at",
                        value: "created_at",
                    }
                ],

            };
        },
        computed: {
            item() {
                return this.$store.getters.getItem;
            },
        },
        methods: {
            formatDate(val, on_time) {
                var data = new Date(val);
                var string = data.getUTCFullYear() +
                    "/" +
                    (data.getUTCMonth() + 1) +
                    "/" +
                    data.getDate();

                if (on_time == null) {
                    string += " " + data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds()

                }
                return (string);

            },

        },
        mounted() {
            this.$store.dispatch(
                "fetchItem",
                "currency/" + this.$route.params.id
            );
        },
    };
</script>

<template>
  <v-container fluid class="icons-page">
    <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
      <v-col>
        <!-- List -->
        <Table
          newItemLabel="All Customers"
          :filter="filter"
          :title="title"
          :api="api"
          :create="create"
          :del="del"
          :edit="edit"
          :headers="headers"
          @openForm="setForm"
          @RowClick="rowClick"
          @update_page="
            (val) => {
              page = val;
            }
          "
        ></Table>
        <!-- Form -->
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_form" max-width="450px">
      <customer-form
        v-if="dialog_form"
        newItemLabel="Edit Customer "
        :isNew="isNew"
        :api="api"
        :page="page"
        @dialogForm="dialog_form = false"
      ></customer-form>
    </v-dialog>

  </v-container>
</template>

<script>
import CustomerForm from '../../components/Forms/CustomerForm.vue';
  export default {
  components: { CustomerForm },
    data() {
      return {
        isNew: true,
        dialog_form: false,
        api: {
          filter :'customers',
          getAll: "customers",
          edit: "customer-update",
          delete: "candidate?candidate_id",
        },
        create: false,
        del: false,
        edit: true,
        filter: "customer",
        title: "All Customers",
        page:1,
        fields: [
          {
            name: "value",
            key: "value",
          },

        ],
        headers: [
          {
            text: "#",
            align: "start",
            sortable: true,
            value: "id",
          },
          {
            text: "first name",
            value: "first_name",
          },
          {
            text: "last name",
            value: "last_name",
          },{
            text: "email",
            value: "email",
          },
          {
            text: "vesion",
            value: "vesion",
          },
          {
            text: "phone",
            value: "phone",
          },
          {
            text: "birthday",
            value: "birthday",
          },

          {
            text: "orders count",
            value: "orders_count",
          },
          {
            text: "points",
            value: "points",
          },
          {
            text: "bills count",
            value: "bills_count",
          },
          {
            text: "money count",
            value: "money_count",
          },
          {
            text: "bank count",
            value: "bank_order_count",
          },
          {
            text: "created at",
            value: "created_at",
          },
          {
            text: "",
            value: "actions",
            sortable: false,
          },
        ],
      };
    },
    methods: {
      rowClick(val) {
      this.$router.push('/customer/'+val.item.id)
    },
      setForm(val) {
        let form = {
          id:null,
          birthday:null,
          can_login:null,
          city:null,
          confirmed:null,
          country:null,
          email:null,
          email_verified_at:null,
          first_name:null,
          gender:null,
          last_name:null,
          phone:null,
          points:null,
          state:null,
          street:null,
        };
        this.$store.dispatch("initForm", form);
        if (val != null) {
          this.isNew = false;
          this.$store.dispatch("setForm", val);
        } else {
          this.isNew = true;
        }
        this.dialog_form = true;
      },
    },
    //  mounted() {
    //   this.$store.dispatch('initForm', this.form)
    //  }
  };
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Input',{attrs:{"label":"Email Address","type":"text","model":_vm.form.email,"errorMessages":_vm.emailErrors,"required":""},on:{"changeValue":(val) => {
                  _vm.form.email = val;
                  _vm.$v.form.email.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Input',{attrs:{"label":"First Name","type":"text","model":_vm.form.first_name,"errorMessages":_vm.first_nameErrors,"required":""},on:{"changeValue":(val) => {
                  _vm.form.first_name = val;
                  _vm.$v.form.first_name.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Input',{attrs:{"label":"Last Name","type":"text","model":_vm.form.last_name,"errorMessages":_vm.last_nameErrors,"required":""},on:{"changeValue":(val) => {
                  _vm.form.last_name = val;
                  _vm.$v.form.last_name.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Input',{attrs:{"label":"phone","type":"text","model":_vm.form.phone,"errorMessages":_vm.phoneErrors,"required":""},on:{"changeValue":(val) => {
                  _vm.form.phone = val;
                  _vm.$v.form.phone.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Input',{attrs:{"label":"phone","type":"text","model":_vm.form.points,"errorMessages":_vm.phoneErrors,"required":""},on:{"changeValue":(val) => {
                  _vm.form.points = val;
                  _vm.$v.form.points.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('switcher',{attrs:{"label":"Block User","type":"text","model":_vm.form.can_login,"errorMessages":_vm.can_loginErrors,"required":""},on:{"switcher":(val) => {
                  _vm.form.can_login = val.value;
                  _vm.$v.form.can_login.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Input',{attrs:{"label":"Country","type":"text","model":_vm.form.country,"errorMessages":_vm.countryErrors,"required":""},on:{"changeValue":(val) => {
                  _vm.form.country = val;
                  _vm.$v.form.country.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"width":"100%","depressed":"","type":"submit","dark":"","color":"primary"},on:{"click":(val) => {
                  this.form.status = !this.form.status;
                }}},[_vm._v(" Update ")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
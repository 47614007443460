<template>
  <form>
    <Title title="Mone Matching"></Title>
    <v-container fluid class="icons-page">
      <v-card max-width="500px" class="pa-5 mx-auto my-5">
        <v-file-input
          v-model="form.csv_file"
          label="File CSV"
          required
        ></v-file-input>

        <v-btn block class="mr-4" color="primary" @click="match">
          submit
        </v-btn>
      </v-card>
    </v-container>
  </form>
</template>

<script>
import Title from "../../components/Title.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { Title },
  data() {
    return {
      api: {
        create: "csv",
      },
    };
  },
  computed: {
    ...mapGetters(["getForm"]),

    form() {
      return this.getForm;
    },
  },
  mounted() {
    let form = {
      csv_file: null,
    };
    this.$store.dispatch("initForm", form);
  },
  methods: {
    match() {
      let formdata = new FormData();
      for (let f in this.form) {
        formdata.append(f, this.form[f]);
      }

      this.$store.dispatch("sendForm", {
        api: this.api,
        form: formdata,
        isNew: true,
      });
    },
  },
};
</script>

<template>
  <VueApexCharts  type="donut" :options="options" :series="series"></VueApexCharts>
  </template>
  
  <script>
  import VueApexCharts from 'vue-apexcharts'
  export default{
    props:{
    series: Array,
    options : Object
  },
  components:{
    VueApexCharts
  },
  data: function() {
    return {
     
    }
  }
}
  </script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('Input',{attrs:{"label":"PayPal Name","type":"text","model":_vm.form.paypal_name,"errorMessages":_vm.ValueErrors},on:{"changeValue":(val) => {
                  _vm.form.paypal_name = val;
                  _vm.$v.form.value.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('TextArea',{attrs:{"label":"Secret","type":"text","model":_vm.form.secret,"errorMessages":_vm.ValueErrors},on:{"changeValue":(val) => {
                  _vm.form.secret = val;
                  _vm.$v.form.value.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('TextArea',{attrs:{"label":"Client","type":"text","model":_vm.form.client,"errorMessages":_vm.ValueErrors},on:{"changeValue":(val) => {
                  _vm.form.client = val;
                  _vm.$v.form.value.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('FormSelectMulti',{attrs:{"label":"Currency","type":"text","model":_vm.form.currency,"errorMessages":_vm.ValueErrors,"items":_vm.currency_items},on:{"select":(val) => {
                  _vm.form.currency = val.value;
                  _vm.$v.form.value.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('FormSelectMulti',{attrs:{"label":"Type","type":"text","model":_vm.form.type,"errorMessages":_vm.ValueErrors,"items":_vm.type_items},on:{"select":(val) => {
                  _vm.form.type = val.value;
                  _vm.$v.form.value.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('FormSelectMulti',{attrs:{"label":"End ID Customer","type":"text","model":_vm.form.EndID,"errorMessages":_vm.ValueErrors,"items":_vm.EndID_items},on:{"select":(val) => {
                  _vm.form.EndID = val.value;
                  _vm.$v.form.value.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-btn',{attrs:{"width":"100%","depressed":"","type":"submit","dark":"","color":"blue darken-1"}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-form @submit.prevent="save">
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <Input
                label="PayPal Name"
                type="text"
                :model="form.paypal_name"
                :errorMessages="ValueErrors"
                @changeValue="
                  (val) => {
                    form.paypal_name = val;
                    $v.form.value.$touch();
                  }
                "
              ></Input>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <TextArea
                label="Secret"
                type="text"
                :model="form.secret"
                :errorMessages="ValueErrors"
                @changeValue="
                  (val) => {
                    form.secret = val;
                    $v.form.value.$touch();
                  }
                "
              ></TextArea>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <TextArea
                label="Client"
                type="text"
                :model="form.client"
                :errorMessages="ValueErrors"
                @changeValue="
                  (val) => {
                    form.client = val;
                    $v.form.value.$touch();
                  }
                "
              ></TextArea>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <FormSelectMulti
                label="Currency"
                type="text"
                :model="form.currency"
                :errorMessages="ValueErrors"
                :items="currency_items"
                @select="
                  (val) => {
                    form.currency = val.value;
                    $v.form.value.$touch();
                  }
                "
              ></FormSelectMulti>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <FormSelectMulti
                label="Type"
                type="text"
                :model="form.type"
                :errorMessages="ValueErrors"
                :items="type_items"
                @select="
                  (val) => {
                    form.type = val.value;
                    $v.form.value.$touch();
                  }
                "
              ></FormSelectMulti>
              </v-col>
              <v-col cols="12" sm="12" md="12">
              <FormSelectMulti
                label="End ID Customer"
                type="text"
                :model="form.EndID"
                :errorMessages="ValueErrors"
                :items="EndID_items"
                @select="
                  (val) => {
                    form.EndID = val.value;
                    $v.form.value.$touch();
                  }
                "
              ></FormSelectMulti>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn
                width="100%"
                depressed
                type="submit"
                dark
                color="blue darken-1"
              >
                Save
              </v-btn>
            </v-col>
           
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [validationMixin],
  props: {
    api: Object,
    isNew: Boolean,

    newItemLabel: {
      default: "عنصر",
    },
  },
  validations: {
    form: {
      paypal_name: {
        required,
      },
      secret: {
        required,
      },
      client: {
        required,
      },
      EndID: {
        required,
      },
      currency: {
        required,
      },
      type: {
        required,
      },

    },
  },
  data() {
    return {
      value_error_msgs: {
        required: "This Field is Required.",
      },
      EndID_items:[
      {'text':'E',
        'value':'E'  
        },
      {'text':'0',
        'value':'0'  
        },
        {'text':'1',
        'value':'1'  
        },
        {'text':'2',
        'value':'2'  
        },
        {'text':'3',
        'value':'3'  
        },
        {'text':'4',
        'value':'4'  
        },
        {'text':'5',
        'value':'5'  
        },
        {'text':'6',
        'value':'6'  
        },
        {'text':'6',
        'value':'6'  
        },
        {'text':'7',
        'value':'7'  
        },
        {'text':'8',
        'value':'8'  
        },
        {'text':'9',
        'value':'9'  
        },
        
      ],
      type_items:[
        {'text':'MONEY',
        'value':'MONEY'  
        },
        {'text':'BILL',
        'value':'BILL'  
        },
        {'text':'UNIT',
        'value':'UNIT'  
        },
        {'text':'BANK',
        'value':'BANK'  
        },],
      currency_items:[
        {'text':'EUR',
        'value':'EUR'  
        },
        {'text':'USD',
        'value':'USD'  
        },
        {'text':'EGP',
        'value':'EGP'  
        },
        {'text':'CHF',
        'value':'CHF'  
        },
        {'text':'DKK',
        'value':'DKK'  
        },
        {'text':'CAD',
        'value':'CAD'  
        },
        {'text':'NOK',
        'value':'NOK'  
        },
        {'text':'SEK',
        'value':'SEK'  
        },
        {'text':'RON',
        'value':'RON'  
        },
        {'text':'AUD',
        'value':'AUD'  
        },
        {'text':'GBP',
        'value':'GBP'  
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getForm"]),
    valueErrors() {
      const errors = [];
      if (!this.$v.form.value.$dirty) return errors;
      !this.$v.form.value.required &&
        errors.push(this.value_error_msgs.required);
      return errors;
    },
    form() {
      return this.getForm;
    },
    formTitle() {
      return (this.isNew ? " Create " : " Edit ") + this.newItemLabel;
    },
  },
  watch: {
    model() {},
  },
  methods: {
    save() {
      console.log(this.form);
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        let formdata = new FormData();
        for (let f in this.form) {
          formdata.append(f, this.form[f]);
        }
        if (!this.isNew) {
          formdata.append("_method", "PUT");
        }
        this.$store.dispatch("sendForm", {
          api: this.api,
          form: formdata,
          isNew: this.isNew,
          page:this.page

        });
        this.$emit("dialogForm", false);
      } else {
        this.$toast.error("Pleace Fill All Fields");
      }
    },
  },
};
</script>

<template>
  <v-container>
    <v-card>
      <v-toolbar dark color="primary"
        >Customer Details #{{ item.id }}</v-toolbar
      >
      <v-container>
        <v-row class="mx-3">

          <v-col cols="12" md="6">
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-account </v-icon>
                  </v-list-item-icon></v-list-item-action
                >
                <v-list-item-content>
                  <v-list-item-title>{{
                    item.first_name + " " + item.last_name
                  }}</v-list-item-title>
                  <v-list-item-subtitle>user name</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-action>
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-calendar-range </v-icon>
                  </v-list-item-icon></v-list-item-action
                >
                <v-list-item-content>
                  <v-list-item-title>{{ item.birthday }}</v-list-item-title>
                  <v-list-item-subtitle>birth day</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-action>
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-map-marker </v-icon>
                  </v-list-item-icon></v-list-item-action
                >

                <v-list-item-content>
                  <v-list-item-title>{{ item.country }}</v-list-item-title>
                  <v-list-item-subtitle>country</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>
              <v-list-item>
                <v-list-item-action>
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-information </v-icon>
                  </v-list-item-icon></v-list-item-action
                >
                
                  <v-chip dark  :color="item.confirmed ? 'green' : 'red'" >
                    confirmed
                  </v-chip>
                  
              </v-list-item>
            </v-list>
          </v-col>
          
          <v-col cols="12" md="6">
          
            <v-list>
              <v-list-item>
                <v-list-item-action
                  ><v-list-item-icon>
                    <v-icon color="indigo"> mdi-email </v-icon>
                  </v-list-item-icon></v-list-item-action
                >

                <v-list-item-content>
                  <v-list-item-title>{{ item.email }}</v-list-item-title>
                  <v-list-item-subtitle>email</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-action
                  ><v-list-item-icon>
                    <v-icon color="indigo"> mdi-phone </v-icon>
                  </v-list-item-icon></v-list-item-action
                >

                <v-list-item-content>
                  <v-list-item-title>{{ item.phone }}</v-list-item-title>
                  <v-list-item-subtitle>phone</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-action>
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-eye-circle </v-icon>
                  </v-list-item-icon></v-list-item-action
                >

                <v-list-item-content>
                  <v-list-item-title>{{
                    formatDate(item.updated_at)
                  }}</v-list-item-title>
                  <v-list-item-subtitle>last seen</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-action>
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-database-plus </v-icon>
                  </v-list-item-icon></v-list-item-action
                >

                <v-list-item-content>
                  <v-list-item-title>{{
                    formatDate(item.created_at)
                  }}</v-list-item-title>
                  <v-list-item-subtitle>create at</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
      <v-col>
        <!-- List -->
        <!-- Tabs -->
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab> Unit Orders </v-tab>
          <v-tab>Bills Orders</v-tab>
          <v-tab>Islamic Orders</v-tab>
          <v-tab>Money Orders</v-tab>
        </v-tabs>
        <v-tabs-items class="mt-5" v-model="tab">
          <v-tab-item>
            <!-- List -->
            <Table
              v-if="tab == 0"
              newItemLabel="Units Orders"
              :filter="filter"
              title="Units Orders"
              :api="api"
              :create="create"
              :del="del"
              :edit="edit"
              :resend="resend"
              :message="message"
              :exchange_sim="exchange_sim"
              :headers="headers"
              @openForm="setForm"
              @update_page="
                (val) => {
                  page = val;
                }
              "
            ></Table>
            <!-- Form -->
          </v-tab-item>

          <v-tab-item>
            <!-- List -->
            <Table
              v-if="tab == 1"
              newItemLabel="Bills Orders"
              :filter="filter"
              title="Bills Orders"
              :api="api_bills"
              :create="create"
              :del="del"
              :edit="edit"
              :headers="headers_bills"
              @openForm="setForm"
            ></Table>
            <!-- Form -->
          </v-tab-item>

          <v-tab-item>
            <!-- List -->
            <Table
              v-if="tab == 2"
              newItemLabel="Islamic Orders"
              :filter="filter"
              title="Islamic Orders"
              :api="api_islamic"
              :create="create"
              :del="del"
              :edit="edit"
              :headers="headers_islamic"
              @openForm="setForm"
            ></Table>
            <!-- Form -->
          </v-tab-item>
          <v-tab-item>
            <!-- List -->
            <Table
              v-if="tab == 3"
              newItemLabel="Money Orders"
              :filter="filter"
              title="Money Orders"
              :api="api_money"
              :create="create"
              :del="del"
              :edit="edit"
              :headers="headers_money"
              @openForm="setForm"
            ></Table>
            <!-- Form -->
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_order_form" max-width="800px">
      <OrderForm
        v-if="dialog_order_form"
        :fields="fields"
        newItemLabel="Order"
        :isNew="isNew"
        :id="id"
        :page="page"
        :api="api"
        @dialogForm="dialog_order_form = false"
      ></OrderForm>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isNew: true,
      dialog_order_form: false,
      tab: 0,
      page: 1,
      api: {
        getAll: "customer_orders?customer_id=" + this.$route.params.id,
        resend: "PayingButNotReceive",
        message: "PayingJustSend",
        exchange_sim: "changeTypeofSIM",
        edit: "updateOrder",
      },
      create: false,
      del: false,
      edit: true,
      resend: true,
      message: true,
      exchange_sim: true,
      api_bills: {
        getAll: "customer_bills?customer_id=" + this.$route.params.id,
      },
      api_islamic: {
        getAll: "customer_islamic?customer_id=" + this.$route.params.id,
      },
      api_money: {
        getAll: "customer_money?customer_id=" + this.$route.params.id,
      },

      filter: "Orders",
      title: "All Customers",
      fields: [
        {
          name: "Active",
          key: "active",
          type: "switch",
        },
        {
          name: "Number",
          key: "beneficiary_no",
          type: "input",
        },
        {
          name: "status",
          key: "status",
          type: "select",
          items: [
            { text: "تمت العملية بنجاح", value: "تمت العملية بنجاح" },
            { text: "قيد معالجة الطلب", value: "قيد معالجة الطلب" },
            { text: "لم يتم الدفع", value: "لم يتم الدفع" },
          ],
        },
        {
          name: "Character currency",
          key: "character_currency",
          type: "select",
          items: [
            { text: "EUR", value: "€" },
            { text: "USD", value: "$" },
            { text: "TRY", value: "₺" },
            { text: "CAD", value: "$" },
            { text: "AUD", value: "$" },
            { text: "AED", value: "د.إ" },
            { text: "SAR", value: "﷼" },
            { text: "QAR", value: "﷼" },
            { text: "BHD", value: "BD" },
            { text: "KWD", value: "د.ك" },
            { text: "EGP", value: "£" },
            { text: "OMR", value: "﷼" },
            { text: "GBP", value: "£" },
            { text: "SEK", value: "kr" },
            { text: "NOK", value: "kr" },
            { text: "CHF", value: "CHF" },
            { text: "RUB", value: "₽" },
            { text: "DKK", value: "Kr." },
          ],
        },
        {
          name: "Currency",
          key: "currency",
          type: "select",
          items: [
            { text: "EUR", value: "EUR" },
            { text: "USD", value: "USD" },
            { text: "TRY", value: "TRY" },
            { text: "CAD", value: "CAD" },
            { text: "AUD", value: "AUD" },
            { text: "AED", value: "AED" },
            { text: "SAR", value: "SAR" },
            { text: "QAR", value: "QAR" },
            { text: "BHD", value: "BHD" },
            { text: "KWD", value: "KWD" },
            { text: "EGP", value: "EGP" },
            { text: "OMR", value: "OMR" },
            { text: "GBP", value: "GBP" },
            { text: "SEK", value: "SEK" },
            { text: "NOK", value: "NOK" },
            { text: "CHF", value: "CHF" },
            { text: "RUB", value: "RUB" },
            { text: "DKK", value: "DKK" },
          ],
        },
        {
          name: "Customer id",
          key: "customer_id",
          type: "input",
        },
        {
          name: "Message template",
          key: "message_template",
          type: "input",
        },
        {
          name: "Paymentmethod",
          key: "payment_method",
          type: "input",
        },
        {
          name: "Price id",
          key: "price_id",
          type: "input",
        },
        {
          name: "Rest",
          key: "rest",
          type: "input",
        },
        {
          name: "is send message ?",
          key: "send_message",
          type: "switch",
        },
        {
          name: "type",
          key: "type_id",
          type: "input",
        },
        {
          name: "on Time",
          key: "on_time",
          type: "input",
        },
        {
          name: "response",
          key: "response",
          type: "input",
        },
      ],
      headers: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "",
          value: "operation",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
        {
          text: "status",
          value: "status_order",
        },
        {
          text: "PayPal",
          value: "response_relation.result",
        },
        {
          text: "text",
          value: "text",
        },
        {
          text: "beneficiary_no",
          value: "beneficiary_no",
        },
        {
          text: "type phone",
          value: "type_phone",
        },
        {
          text: "price",
          value: "price_now",
        },
        {
          text: "units",
          value: "pricing.units",
        },

        {
          text: "payment method",
          value: "response_relation.payment_method",
        },
       
        {
          text: "response",
          value: "response",
        },
        {
          text: "created at",
          value: "created_at",
        },
        // {
        //   text: "",
        //   value: "actions",
        //   sortable: false,
        // },
      ],
      headers_money: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "customer_id",
          value: "customer_id",
        },
        {
          text: "amount in S.P",
          value: "amount_sy",
        },
        {
          text: "amount in currency",
          value: "amount",
        },
        {
          text: "currency",
          value: "currency",
        },
        {
          text: "response",
          value: "response",
        },
        {
          text: "status",
          value: "status_order",
        },
        {
          text: "payment_method",
          value: "payment_method",
        },
        {
          text: "company_name",
          value: "company_name",
        },
        {
          text: "notes",
          value: "notes",
        },
        {
          text: "beneficiary_name",
          value: "beneficiary_name",
        },
        {
          text: "beneficiary_no",
          value: "beneficiary_no",
        },
        {
          text: "created at",
          value: "created_at",
        },
        // {
        //   text: "",
        //   value: "actions",
        //   sortable: false,
        // },
      ],
      headers_islamic: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "service",
          value: "code",
        },
        {
          text: "amount in S.P",
          value: "amount",
        },
        {
          text: "amount in currency",
          value: "amount_currency",
        },
        {
          text: "currency",
          value: "currency",
        },
        {
          text: "price",
          value: "currency_price",
        },
        {
          text: "message",
          value: "message.text",
        },
        {
          text: "response",
          value: "status_response",
        },
        {
          text: "created at",
          value: "created_at",
        },
        // {
        //   text: "",
        //   value: "actions",
        //   sortable: false,
        // },
      ],
      headers_bills: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "telephone",
          value: "telephone",
        },
        {
          text: "beneficiary_no",
          value: "beneficiary_no",
        },
        {
          text: "status",
          value: "status",
        },
        {
          text: "service",
          value: "service.name_ar",
        },
        {
          text: "provider",
          value: "provider.name_ar",
        },
        {
          text: "speed",
          value: "children.name_ar",
        },
        
        {
          text: "response",
          value: "response",
        },
        {
          text: "created at",
          value: "created_at",
        },
        // {
        //   text: "",
        //   value: "actions",
        //   sortable: false,
        // },
      ],
    };
  },
  computed: {
    item() {
      return this.$store.getters.getItem;
    },
    randomColor() {
      const r = () => Math.floor(256 * Math.random());

      return `rgb(${r()}, ${r()}, ${r()})`;
    },
  },
  methods: {
    formatDate(val, on_time) {
      var data = new Date(val);
      var string =
        data.getUTCFullYear() +
        "/" +
        (data.getUTCMonth() + 1) +
        "/" +
        data.getDate();

      if (on_time == null) {
        string +=
          " " +
          data.getHours() +
          ":" +
          data.getMinutes() +
          ":" +
          data.getSeconds();
      }
      return string;
    },

    setForm(val) {
      this.id = val.id;
      let form = {
        send_message: null,
        type_id: null,
        rest: null,
        price_id: null,
        status: null,
        payment_method: null,
        message_template: null,
        customer_id: null,
        country: null,
        character_currency: null,
        currency: null,
        beneficiary_no: null,
        active: null,
      };
      this.$store.dispatch("initForm", form);
      if (val != null) {
        this.isNew = false;
        this.$store.dispatch("setForm", val);
      } else {
        this.isNew = true;
      }
      this.dialog_order_form = true;
    },
  },
  mounted() {
    this.$store.dispatch(
      "fetchItem",
      "customer_profile?id=" + this.$route.params.id
    );
  },
};
</script>

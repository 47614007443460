<template>
  <v-form @submit.prevent="save">
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <Input
                label="Email Address"
                type="text"
                :model="form.email"
                :errorMessages="emailErrors"
                @changeValue="
                  (val) => {
                    form.email = val;
                    $v.form.email.$touch();
                  }
                "
                required
              ></Input>
            </v-col>
            <v-col cols="12">
              <Input
                label="First Name"
                type="text"
                :model="form.first_name"
                :errorMessages="first_nameErrors"
                @changeValue="
                  (val) => {
                    form.first_name = val;
                    $v.form.first_name.$touch();
                  }
                "
                required
              ></Input>
            </v-col>
            <v-col cols="12">
              <Input
                label="Last Name"
                type="text"
                :model="form.last_name"
                :errorMessages="last_nameErrors"
                @changeValue="
                  (val) => {
                    form.last_name = val;
                    $v.form.last_name.$touch();
                  }
                "
                required
              ></Input>
            </v-col>
            <v-col cols="12">
              <Input
                label="phone"
                type="text"
                :model="form.phone"
                :errorMessages="phoneErrors"
                @changeValue="
                  (val) => {
                    form.phone = val;
                    $v.form.phone.$touch();
                  }
                "
                required
              ></Input>
            </v-col>
            <v-col cols="12">
              <Input
                  label="phone"
                  type="text"
                  :model="form.points"
                  :errorMessages="phoneErrors"
                  @changeValue="
                  (val) => {
                    form.points = val;
                    $v.form.points.$touch();
                  }
                "
                  required
              ></Input>
            </v-col>
            <v-col cols="12">
              <switcher
                label="Block User"
                type="text"
                :model="form.can_login"
                :errorMessages="can_loginErrors"
                @switcher="
                  (val) => {
                    form.can_login = val.value;
                    $v.form.can_login.$touch();
                  }
                "
                required
              ></switcher>
            </v-col>
            <v-col cols="12">
              <Input
                label="Country"
                type="text"
                :model="form.country"
                :errorMessages="countryErrors"
                @changeValue="
                  (val) => {
                    form.country = val;
                    $v.form.country.$touch();
                  }
                "
                required
              ></Input>
            </v-col>
            <v-col cols="12">
              <v-btn
                width="100%"
                depressed
                type="submit"
                dark
                color="primary"
                @click="
                  (val) => {
                    this.form.status = !this.form.status;
                  }
                "
              >
                Update
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import Switcher from '../Form Components/Switcher.vue';
export default {
  components: {  Switcher },
  mixins: [validationMixin],
  props: {
    api: Object,
    isNew: Boolean,
    headers: Object,
    page: {
      default: 1,
    },
    newItemLabel: {
      default: "عنصر",
    },
  },
  validations: {
    form: {
      value: {
        required,
      },
    },
  },
  data() {
    return {
      value_error_msgs: {
        required: "This Field is Required.",
      },
    };
  },
  computed: {
    ...mapGetters(["getForm"]),
    valueErrors() {
      const errors = [];
      if (!this.$v.form.value.$dirty) return errors;
      !this.$v.form.value.required &&
        errors.push(this.value_error_msgs.required);
      return errors;
    },
    form() {
      return this.getForm;
    },
    formTitle() {
      return this.newItemLabel;
    },
  },
  watch: {
    model() {},
  },
  methods: {
    save() {
      this.$store.dispatch("sendForm", {
        api: this.api,
        form: this.form,
        isNew: this.isNew,
        page: this.page,
      });
      this.$emit("dialogForm", false);
    },
  },
};
</script>

<template>
  <v-container fluid class="icons-page">
    <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
      <v-col>
        <!-- List -->
        <Table
          newItemLabel="All Orders"
          :filter="filter"
          :title="title"
          :api="api"
          :create="create"
          :del="del"
          :edit="edit"
          :resend="resend"
          :block="block"
          :message="message"
          :exchange_sim="exchange_sim"
          :recovery_update_order="recovery_update_order"

          :headers="headers"
          @openForm="setForm"
          @update_page="(val )=>{page = val}"

        ></Table>
        <!-- Form -->
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_form" max-width="500px">
      <OrderForm
        v-if="dialog_form"
        :fields="fields"
        newItemLabel="Order"
        :isNew="isNew"
        :id="id"
        :page="page"
        :api="api"
        @dialogForm="dialog_form = false"
      ></OrderForm>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isNew: true,
      dialog_form: false,
      page:1,
      filter: "orders",

      api: {
        getAll: "failWithOrderRest",
        filter: "failWithOrderRest",
        resend: "PayingButNotReceive",
        message: "PayingJustSend",
        exchange_sim: "changeTypeofSIM",
        edit: "updateOrder",
        recovery_update_order:"recovery_update_order",

        delete: "order_delete?id",
        block: "block_order",
        type:"UNITE",
      },
      del:true,
      block:true,
      create: false,
      edit: true,
      resend: true,
      message: true,
      exchange_sim: true,
      recovery_update_order:true,

      title: "Rest Orders",
      fields: [
        {
          name: "Active",
          key: "active",
        },
        {
          name: "Number",
          key: "beneficiary_no",
        },
        {
          name: "status",
          key: "status",
        },
        {
          name: "Character currency",
          key: "character_currency",
        },{
          name: "Currency",
          key: "currency",
        },{
          name: "country",
          key: "country",
        },{
          name: "Customer id",
          key: "customer_id",
        },{
          name: "Message template",
          key: "message_template",
        },{
          name: "Paymentmethod",
          key: "payment_method",
        },{
          name: "Price id",
          key: "price_id",
        },{
          name: "Rest",
          key: "rest",
        },{
          name: "is send message ?",
          key: "send_message",
        },{
          name: "type",
          key: "type_id",
        }
        ,{
          name: "on Time",
          key: "on_time",
        }
        ,{
          name: "response",
          key: "response",
        }
      ],
      headers: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",
        }
        ,
        {
          text: "",
          value: "operation",
          sortable: false,
        },
        {
          text: "actions",
          value: "actions",
          sortable: false,
        },
        {
          text: "Price Now",
          value: "price_now",
        },
        {
          text: "customer id",
          value: "customer_id",
        },
        {
          text: "rest",
          value: "rest",
        },
        {
          text: "PayPal",
          value: "response_relation.result",
        },
        {
          text: "response",
          value: "response",
        },
        {
          text: "unite",
          value: "pricing.units",
        },
        {
          text: "beneficiary_no",
          value: "beneficiary_no",
        },
        {
          text: "status",
          value: "status_order",
        },
        
        {
          text: "type phone",
          value: "type_phone",
        },
          {
          text: "friends",
          value: "friend.error_response",
        },
        {
          text: "PayPal Account",
          value: "paypal_name",
        },{
          text: "payment method",
          value: "payment_method",
        },
        {
          text: "profit",
          value: "profit",
        },
        {
          text: "created_at",
          value: "created_at",
        },
        
      ],
    };
  },
  methods: {
    setForm(val) {
      this.id = val.id;
      let form = {
        send_message: null,
        type_id: null,
        rest: null,
        price_id: null,
        status:null,
        payment_method: null,
        message_template: null,
        customer_id: null,
        country: null,
        character_currency: null,
        currency: null,
        beneficiary_no: null,
        active: null,
        on_time:null,
        response:null,
      };
      this.$store.dispatch("initForm", form);
      if (val != null) {
        this.isNew = false;
        this.$store.dispatch("setForm", val);
      } else {
        this.isNew = true;
      }
      this.dialog_form = true;
    },
  },
  //  mounted() {
  //   this.$store.dispatch('initForm', this.form)
  //  }
};
</script>

<template>
    <v-container fluid class="icons-page">

        <v-card class="mx-auto ">
            <v-container>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-list-item>

                            <v-list-item-avatar size="100">
                                <v-avatar>
                                    <span class="white--text text-h5"><v-icon>mdi-card</v-icon></span>
                                </v-avatar>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title class="title">{{item.name}}</v-list-item-title>
                            </v-list-item-content>

                        </v-list-item>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>

        </v-card>
        <v-row>
            <v-col>

                <form @submit="add">
                    <v-container fluid class="icons-page">

                        <v-card class="pa-6 mx-auto my-5 ">
                            <Title title="Add Tax To Payment" class="text-center"></Title>
                            <v-row class="pa-10">
                                <v-select
                                        :items="items"
                                        required
                                        :model="form.name"
                                        @change="(val) => {form.name = val}"
                                        attr="name"
                                >
                                </v-select>
                                <v-btn block class="mr-4" color="primary" type="submit">
                                    Add
                                </v-btn>
                            </v-row>
                        </v-card>
                    </v-container>
                </form>
            </v-col>
        </v-row>
        <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2  ">
            <v-col>
                <Table
                        v-if="tab == 0"
                        newItemLabel="All Taxes"
                        :filter="filter"
                        title="All Taxes"
                        :api="api"
                        :create="create"
                        :del="del"
                        :edit="edit"
                        :headers="headers"
                        @update_page="(val )=>{page = val}"

                ></Table>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog_order_form" max-width="800px">
            <OrderForm
                    v-if="dialog_order_form"
                    :fields="fields"
                    newItemLabel="Order"
                    :isNew="isNew"
                    :id="id"
                    :page="page"
                    :api="api"
                    @dialogForm="dialog_order_form = false"
            ></OrderForm>
        </v-dialog>

    </v-container>

</template>

<script>
    import {mapGetters, mapActions} from "vuex";

    export default {
        data() {
            return {
                isNew: true,

                dialog_order_form: false,
                tab: 0,
                page: 1,
                api: {
                    getAll: "taxes_by_payment_method?payment_method_id=" + this.$route.params.id,
                    create: "add_tax_to_payment",
                    message: "PayingJustSend",
                    exchange_sim: "changeTypeofSIM",
                    edit: "updateOrder",
                },
                create: false,
                del: true,
                edit: false,
                resend: true,
                message: true,
                exchange_sim: true,


                filter: "Orders",
                title: "All Taxes",
                headers: [
                    {
                        text: "#",
                        align: "start",
                        sortable: true,
                        value: "id",
                    },
                    {
                        text: "",
                        value: "operation",
                        sortable: false,
                    },

                    {
                        text: "name",
                        value: "name",
                    },
                    {
                        text: "amount",
                        value: "amount",
                    },
                    {
                        text: "description",
                        value: "description",
                    },
                    {
                        text: "transaction date",
                        value: "transaction_date",
                    },
                    {
                        text: "type",
                        value: "type",
                    },
                    {
                        text: "created at",
                        value: "created_at",
                    }
                ],

            };
        },
        computed: {
            item() {
                return this.$store.getters.getItem;
            },
            items() {

                var items = this.$store.getters.getItems;
                var data = [];
                console.log(items)

                items.forEach(function (element) {
                    data.push(element.name)
                });
                return data;
            },

            ...mapGetters(["getForm"]),

            form() {
                return this.getForm;
            },
        },
        methods: {
            formatDate(val, on_time) {
                var data = new Date(val);
                var string = data.getUTCFullYear() +
                    "/" +
                    (data.getUTCMonth() + 1) +
                    "/" +
                    data.getDate();

                if (on_time == null) {
                    string += " " + data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds()

                }
                return (string);

            },
            add() {
                let formdata = new FormData();
                for (let f in this.form) {
                    formdata.append(f, this.form[f]);
                }

                this.$store.dispatch("sendForm", {
                    api: this.api,
                    form: formdata,
                    isNew: true,
                });
            }

        },
        mounted() {

            let form = {
                name: null,
                payment_method_id: this.$route.params.id,
            };
            this.$store.dispatch("initForm", form);

            this.$store.dispatch(
                "fetchItems",
                "taxes_all"
            );

            this.$store.dispatch(
                "fetchItem",
                "payment_method/" + this.$route.params.id
            );

        },
    };
</script>

import axios from "axios";
const state = () => ({
  resultData: { gender: [], pricing: [], message: [],orders:[],countries:[],bank:[] },
});

const actions = {
  async fetchDashboard({ commit }, api_info) {
    await axios
      .get("gender-percentage")
      .then((res) => {
        commit("SET_GENDER", res.data.data);
      })
      .catch((err) => {});

    await axios
      .get("pricing-percentage")
      .then((res) => {
        commit("SET_PRICING", res.data.data);
      })
      .catch((err) => {});
    await axios
      .get("most-used-messages")
      .then((res) => {
        commit("SET_MESSAGES", res.data.data);
      })
      .catch((err) => {});

      await axios
      .get("order-statistics")
      .then((res) => {
        commit("SET_ORDERS", res.data.data);
      })
      .catch((err) => {});
      await axios
      .get("countries-percentage")
      .then((res) => {
        commit("SET_COUNTRIES", res.data.data);
      })
      .catch((err) => {});
      await axios
      .get("bank-enquiry-statistics")
      .then((res) => {
        commit("SET_BANK", res.data.data);
      })
      .catch((err) => {});
      
      
  },
};

const getters = {
  getDashData: (state) => {
    return state.resultData;
  },
};

const mutations = {
  SET_GENDER(state, payload) {
    state.resultData.gender = [
      {
        data: [
          {
            x: "Male",
            y: Math.round(payload.males),
          },
          {
            x: "Females",
            y: Math.round(payload.females),
          },
        ],
      },
    ];
  },
  SET_BANK(state, payload){
    state.resultData.bank = payload
  },
  SET_COUNTRIES(state, payload) {
    var countries = []
    payload.forEach((element) => {

      countries.push({
        x: element.country,
        y: element.users,
      });
    });

    state.resultData.countries = [
      {
        data: countries,
      },
    ];
  },
  SET_ORDERS(state, payload) {
    state.resultData.orders = {
      series :[],
      options: []}
   
    var counter = [];
    var options = {
      labels: []
    }
    Object.keys(payload).forEach(function(key) {
      console.log(key);
      counter.push(payload[key])
      options.labels.push(key)
    })
    state.resultData.orders.series= counter;
    state.resultData.orders.options= options;
  },
  SET_MESSAGES(state, payload) {
    state.resultData.message = {
      series :[],
      options: []}
    var message = [];
    var options = {
      labels: []
    }

    payload.forEach(element=>{
      message.push(element.message_id)
      options.labels.push({
        x: element.message_id,
        y: element.counter,
      })
    })
    state.resultData.message.series=[
      {
        data: message,
      },
    ];
    state.resultData.message.options= options;
  },

  SET_PRICING(state, payload) {
    var prices = [];
    payload.forEach((element) => {

      prices.push({
        x: element.price_id,
        y: Math.round(element.percentage),
      });
    });

    state.resultData.pricing = [
      {
        data: prices,
      },
    ];
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Dashboard</h1>
        <v-menu offset-y>
          
        </v-menu>
      </v-row>
      
      
      <v-row>
        <v-col lg="6" sm="6" cols="12" v-if="statistics.orders">
          <v-card class="mx-1 mb-1">
            <v-card-title>Orders Used </v-card-title>

            <BarChart :series="statistics.orders.series" :options="statistics.orders.options"></BarChart>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" cols="12" v-if="statistics.gender">
          <v-card class="mx-1 mb-1">
            <v-card-title>Gender</v-card-title>
            <ColumnChart :series="statistics.gender"></ColumnChart>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" cols="12" v-if="statistics.pricing">
          <v-card class="mx-1 mb-1">
            <v-card-title>Pricing Units</v-card-title>
            <ColumnChart :series="statistics.pricing"></ColumnChart>
          </v-card>
        </v-col>
        <v-col lg="6" sm="6" cols="12" v-if="statistics.countries">
          <v-card class="mx-1 mb-1">
            <v-card-title>Countries </v-card-title>

            <ColumnChart :series="statistics.countries" ></ColumnChart>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" cols="12">
          <v-card class="mx-1 mb-1">
            <v-card-title>Messages Used </v-card-title>

            <ColumnChart :series="statistics.message.series" :options="statistics.message.options"></ColumnChart>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import BarChart from "../../components/BarChart.vue";
import ColumnChart from "../../components/ColumnChart.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Dashboard",
  components: {
    BarChart,
    ColumnChart,
  },
  
  computed: {
    ...mapGetters(["getDashData"]),
    statistics() {
      return this.getDashData;
    },
  },
  methods: {
    ...mapActions(["fetchDashboard"]),
  },
  beforeMount() {
    this.fetchDashboard();
  },
};
</script>

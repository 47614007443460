<template>
    <v-form @submit.prevent="save">
      <v-card>
        <v-card-title>
          <span>{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12">
  
              <v-list-item three-line>
                <v-list-item-content>
                  <div v-for="item in headers" :key="item">
                  <v-list-item-title class="text-overline mb-1" v-if="item.text!=''">
                    {{ item.value }} :{{form[`${item.value}`]}}
                  </v-list-item-title>
                  </div>
  
                </v-list-item-content>
              </v-list-item>
              </v-col>
              <v-col cols="4" sm="6" md="4">
                <v-btn 
              type="button"
              color="primary"
              v-clipboard:copy="message_to_bot"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
      >
        copy
      </v-btn>
              </v-col>
              <v-col cols="8" sm="6" md="8">
                <TextArea
                  label="bot"
                  type="text"
                  :model="message_to_bot"                
                ></TextArea>
                </v-col>
               
              <v-col cols="12" sm="6" md="12">
                <TextArea
                  label="notes"
                  type="text"
                  :model="form.notes"
                  :errorMessages="ValueErrors"
                  @changeValue="
                    (val) => {
                      form.notes = val;
                      $v.form.notes.$touch();
                    }
                  "
                ></TextArea>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-btn width="100%" depressed type="submit" dark color="green"
                @click="
                    (val) => {
                      this.form.status = !this.form.status;
                    }
                  "
                >
                  yes
                </v-btn>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-btn
                  width="100%"
                  depressed
                  dark
                  color="red"
                  @click="$emit('dialogForm',false)"
                >
                  No
                </v-btn></v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </template>
  
  <script>
  
  import { validationMixin } from "vuelidate";
  import {
    required,
    maxLength,
    minLength,
    email,
    sameAs,
  } from "vuelidate/lib/validators";
  import { mapGetters, mapActions } from "vuex";
  import TextArea from '../Form Components/TextArea.vue';
  export default {
    props: {
      api: Object,
      isNew: Boolean,
      headers:Object,
      page:{
        default:1
      },
      newItemLabel: {
        default: "عنصر",
      },
    },
    validations: {
      form: {
        value: {
          required,
        },
      },
    },
    data() {
      return {
        value_error_msgs: {
          required: "This Field is Required.",
        },
        message_to_bot:''
      };
    },
    computed: {
      ...mapGetters(["getForm"]),
      valueErrors() {
        const errors = [];
        if (!this.$v.form.value.$dirty) return errors;
        !this.$v.form.value.required &&
          errors.push(this.value_error_msgs.required);
        return errors;
      },
      form() {
        return this.getForm;
      },
      formTitle() {
        return  this.newItemLabel;
      },
    },
    watch: {
      model() {},
    },
    methods: {
      save() {
        console.log(this.api)
          this.$store.dispatch("createTransaction", {
            api: this.api,
            form: this.form,
            isNew: this.isNew,
            page:this.page
  
          });
          this.$emit("dialogForm", false);
        
      },
      onCopy: function (e) {
        this.$toast.success('You just copied the  text to the clipboard');
  
              },
      onError: function (e) {
        this.$toast.error('Failed to copy the text to the clipboard');
  
                 
                  console.log(e);
              }
     
    },
   async mounted(){
  
  
      await axios
              .post(`${ this.api.create }`, this.form)
              .then(res => {
                  let resultData = res.data;
                  this.message_to_bot = resultData
  
              })
              .catch(err => {
              });
  
     
    }
  };
  </script>
  
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"amount in currency","type":"text","model":_vm.AmountCurrency == null ? _vm.form.amount : _vm.AmountCurrency},on:{"changeValue":(val) => {
                  _vm.form.amount = val;
                }}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeAmount(_vm.form.amount)}}},[_vm._v(" to SYP ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"amount in sy","type":"text","model":_vm.AmountSY == null ? _vm.form.amount_sy : _vm.AmountSY,"debounce":true},on:{"changeValue":(val) => {
                  _vm.form.amount_sy = val;
                }}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeAmountSY(_vm.form.amount_sy)}}},[_vm._v(" to CURRENCY ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('Input',{attrs:{"label":"beneficiary number","type":"text","model":_vm.form.beneficiary_no},on:{"changeValue":(val) => {
                  _vm.form.beneficiary_no = val;
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"beneficiary name","type":"text","model":_vm.form.beneficiary_name},on:{"changeValue":(val) => {
                  _vm.form.beneficiary_name = val;
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"status","type":"text","model":_vm.form.status},on:{"changeValue":(val) => {
                  _vm.form.status = val;
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"notes","type":"text","model":_vm.form.notes},on:{"changeValue":(val) => {
                  _vm.form.notes = val;
                }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-color-picker',{attrs:{"dot-size":"10","hide-canvas":"","hide-sliders":"","mode":"hexa","show-swatches":""},on:{"change":(val) => {
                  _vm.form.color = val.hex;
                }},model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", $$v)},expression:"form.color"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('Button',{attrs:{"color":"blue darken-1","type":"submit","label":"Save"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
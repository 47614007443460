import axios from "axios";
const state = () => ({
  amount: null,
  amount_sy:null

});

const actions = {
  EmptyAmount({ commit }){
    commit("SET_AMOUNT", null);        
    commit("SET_AMOUNT_SY", null);  

  },
  async fetchExchangeMoney({ commit },params) {
    await axios
      .post(`money-exchange`,params)
      .then((res) => {
        var resultData = res.data.data
        console.log(resultData);
        if(resultData.currency != 'SYP'){
          commit("SET_AMOUNT", resultData.amount);        }
          else{
          commit("SET_AMOUNT_SY", resultData.amount);  }
      })
      .catch((err) => {
        commit("SET_SUPPORT_MESSAGES", err);
      });
    },
 
    

};

const getters = {
  getAmountSY: (state) => {
    return state.amount_sy;
  },
  getAmount: (state) => {
    return state.amount;
  },
};

const mutations = {
  SET_AMOUNT_SY(state, payload) {
    state.amount_sy = payload;
  },
  SET_AMOUNT(state, payload) {
    state.amount = payload;
  },
  
};

export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
  <v-form @submit.prevent="save">
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <template v-for="n in steps">
                <v-stepper-step
                  :key="`${n}-step`"
                  :complete="e1 > n"
                  :step="n"
                  editable
                >
                  Step {{ n }}
                </v-stepper-step>

                <v-divider v-if="n !== steps" :key="n"></v-divider>
              </template>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content key="1-content" step="1">
                <v-row class="mt-3">
                  <v-col cols="4" sm="4" md="4">
                    <Input
                      label="Currency Name"
                      type="text"
                      :model="form.name"
                      @changeValue="
                        (val) => {
                          form.name = val;
                        }
                      "
                    ></Input>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <Input
                      label="Currency Code"
                      type="text"
                      :model="form.code"
                      @changeValue="
                        (val) => {
                          form.code = val;
                        }
                      "
                    ></Input>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <Input
                      label="Currency Symbol"
                      type="text"
                      :model="form.symbol"
                      @changeValue="
                        (val) => {
                          form.symbol = val;
                        }
                      "
                    ></Input> </v-col
                  ><v-col cols="12" sm="12" md="12">
                    <TextArea
                      label="PayPal Arabic Message"
                      type="text"
                      :model="duration_paypal_ar"
                      @changeValue="
                        (val) => {
                          duration_paypal_ar = val;
                        }
                      "
                    ></TextArea> </v-col
                  ><v-col cols="12" sm="12" md="12">
                    <TextArea
                      label="PayPal English Message"
                      type="text"
                      :model="duration_paypal_en"
                      @changeValue="
                        (val) => {
                          duration_paypal_en = val;
                        }
                      "
                    ></TextArea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <TextArea
                      label="wise Arabic Message"
                      type="text"
                      :model="duration_wise_ar"
                      @changeValue="
                        (val) => {
                          duration_wise_ar = val;
                        }
                      "
                    ></TextArea> </v-col
                  ><v-col cols="12" sm="12" md="12">
                    <TextArea
                      label="wise English Message"
                      type="text"
                      :model="duration_wise_en"
                      @changeValue="
                        (val) => {
                          duration_wise_en = val;
                        }
                      "
                    ></TextArea>
                  </v-col>
                </v-row>

                <v-btn color="primary" @click="nextStep(1)"> Continue </v-btn>

                <v-btn text> Cancel </v-btn>
              </v-stepper-content>
              <v-stepper-content key="2-content" step="2">
                <v-row class="mt-3">
                  <v-col cols="4" sm="4" md="4">
                    <Input
                      label="Min"
                      type="text"
                      :model="form.min"
                      @changeValue="
                        (val) => {
                          form.min = val;
                        }
                      "
                    ></Input> </v-col
                  ><v-col cols="4" sm="4" md="4">
                    <Input
                      label="Max"
                      type="text"
                      :model="form.max"
                      @changeValue="
                        (val) => {
                          form.max = val;
                        }
                      "
                    ></Input> </v-col
                  ><v-col cols="4" sm="4" md="4">
                    <Input
                      label="Avg"
                      type="text"
                      :model="form.avg"
                      @changeValue="
                        (val) => {
                          form.avg = val;
                        }
                      "
                    ></Input> </v-col
                  ><v-col cols="12" sm="12" md="12">
                    <TextArea
                      label="Details Arabic"
                      type="text"
                      :model="form.details_ar"
                      @changeValue="
                        (val) => {
                          form.details_ar = val;
                        }
                      "
                    ></TextArea> </v-col
                  ><v-col cols="12" sm="12" md="12">
                    <TextArea
                      label="Details English"
                      type="text"
                      :model="form.details_en"
                      @changeValue="
                        (val) => {
                          form.details_en = val;
                        }
                      "
                    ></TextArea> </v-col
                  ><v-col cols="12" sm="12" md="12">
                    <TextArea
                      label="Details Bank Arabic"
                      type="text"
                      :model="form.details_bank_ar"
                      @changeValue="
                        (val) => {
                          form.details_bank_ar = val;
                        }
                      "
                    ></TextArea> </v-col
                  ><v-col cols="12" sm="12" md="12">
                    <TextArea
                      label="Details Bank English"
                      type="text"
                      :model="form.details_bank_en"
                      @changeValue="
                        (val) => {
                          form.details_bank_en = val;
                        }
                      "
                    ></TextArea> </v-col
                  ><v-col cols="4" sm="4" md="4">
                    <Input
                      label="Rate to Sp"
                      type="text"
                      :model="form.rate_to_sp"
                      @changeValue="
                        (val) => {
                          form.rate_to_sp = val;
                        }
                      "
                    ></Input>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <Input
                      label="Tax Lower Wise"
                      type="text"
                      :model="form.tax_lower_wise"
                      @changeValue="
                        (val) => {
                          form.tax_lower_wise = val;
                        }
                      "
                    ></Input>
                  </v-col>

                  

                  <v-col cols="4" sm="4" md="4">
                    <Input
                      label="Fee Lower Wise"
                      type="text"
                      :model="form.fee_lower_wise"
                      @changeValue="
                        (val) => {
                          form.fee_lower_wise = val;
                        }
                      "
                    ></Input>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <Input
                      label="Fee Upper Wise"
                      type="text"
                      :model="form.fee_upper_wise"
                      @changeValue="
                        (val) => {
                          form.fee_upper_wise = val;
                        }
                      "
                    ></Input>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <Input
                      label="Fee Lower Paypal"
                      type="text"
                      :model="form.fee_lower_paypal"
                      @changeValue="
                        (val) => {
                          form.fee_lower_paypal = val;
                        }
                      "
                    ></Input>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <Input
                      label="Fee Upper Paypal"
                      type="text"
                      :model="form.fee_upper_paypal"
                      @changeValue="
                        (val) => {
                          form.fee_upper_paypal = val;
                        }
                      "
                    ></Input>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <Input
                      label="Icon"
                      type="text"
                      :model="form.icon"
                      @changeValue="
                        (val) => {
                          form.icon = val;
                        }
                      "
                    ></Input>
                  </v-col>
                </v-row>
                <v-btn color="primary" type="submit"> save </v-btn>

                <v-btn text> Cancel </v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [validationMixin],
  props: {
    api: Object,
    isNew: Boolean,

    newItemLabel: {
      default: "عنصر",
    },
  },
  validations: {
    form: {
      name: { required },
      code: { required },
      symbol: { required },
      duration_paypal: { required },
      duration_wise: { required },
      icon: { required },
      min: { required },
      max: { required },
      avg: { required },
      details_ar: { required },
      details_en: { required },
      details_bank_ar: { required },
      details_bank_en: { required },
      rate_to_sp: { required },
      tax_lower_wise: { required },
      fee_lower_wise: { required },
      fee_upper_wise: { required },
      fee_lower_paypal: { required },
      fee_upper_paypal: { required },
    },
  },
  data() {
    return {
      value_error_msgs: {
        required: "This Field is Required.",
      },
      e1: 1,
      steps: 2,
      EndID_items: [
        { text: "0", value: "0" },
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "6", value: "6" },
        { text: "7", value: "7" },
        { text: "8", value: "8" },
        { text: "9", value: "9" },
      ],
      type_items: [
        { text: "MONEY", value: "MONEY" },
        { text: "BILL", value: "BILL" },
        { text: "UNIT", value: "UNIT" },
        { text: "BANK", value: "BANK" },
      ],
      currency_items: [
        { text: "EUR", value: "EUR" },
        { text: "USD", value: "USD" },
        { text: "EGP", value: "EGP" },
        { text: "CHF", value: "CHF" },
        { text: "DKK", value: "DKK" },
        { text: "CAD", value: "CAD" },
        { text: "NOK", value: "NOK" },
        { text: "SEK", value: "SEK" },
        { text: "RON", value: "RON" },
        { text: "AUD", value: "AUD" },
        { text: "GBP", value: "GBP" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getForm"]),
    valueErrors() {
      const errors = [];
      if (!this.$v.form.value.$dirty) return errors;
      !this.$v.form.value.required &&
        errors.push(this.value_error_msgs.required);
      return errors;
    },
    form() {
      return this.getForm;
    },
    formTitle() {
      return (this.isNew ? " Create " : " Edit ") + this.newItemLabel;
    },
    duration_paypal_ar: {
      set(val) {
        const values = this.form.duration_paypal ? this.form.duration_paypal.split(" | ") : " | ".split(" | ");
        values[1] = val;
        this.form.duration_paypal = values.join(" | ");
      },
      get() {
        return this.form.duration_paypal ? this.form.duration_paypal.split(" | ")[1] : null;
      },
    },
    duration_paypal_en: {
      set(val) {
        const values = this.form.duration_paypal ? this.form.duration_paypal.split(" | ") : " | ".split(" | ");
        values[0] = val;
        this.form.duration_paypal = values.join(" | ");
      },
      get() {
        return this.form.duration_paypal ? this.form.duration_paypal.split(" | ")[0] : null;
      },
    },
    duration_wise_ar: {
      set(val) {
        const values = this.form.duration_wise ? this.form.duration_wise.split(" | ") : " | ".split(" | ");
        values[1] = val;
        this.form.duration_wise = values.join(" | ");
      },
      get() {
        return this.form.duration_wise ? this.form.duration_wise.split(" | ")[1] :null;
      },
    },
    duration_wise_en: {
      set(val) {
        const values = this.form.duration_wise ? this.form.duration_wise.split(" | ") : " | ".split(" | ");
        values[0] = val;
        this.form.duration_wise = values.join(" | ");
      },
      get() {
        return this.form.duration_wise ? this.form.duration_wise.split(" | ")[0] : null;
      },
    },
  },

  watch: {
    model() {},
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  methods: {
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    save() {
      console.log(this.form);
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        let formdata = new FormData();
        for (let f in this.form) {
          formdata.append(f, this.form[f]);
        }
        if (!this.isNew) {
          formdata.append("_method", "PUT");
        }
        this.$store.dispatch("sendForm", {
          api: this.api,
          form: formdata,
          isNew: this.isNew,
          page: this.page,
        });
        this.$emit("dialogForm", false);
      } else {
        this.$toast.error("Pleace Fill All Fields");
      }
    },
  },
};
</script>

<template>
    <v-container fluid class="icons-page">
      <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
        <v-col>
          <!-- List -->
          <Table
            newItemLabel="Coupons"
            :title="title"
            :api="api"
            :create="create"
            :del="del"
            :edit="edit"
            :filter="filter"
            :headers="headers"
            @openForm="setForm"
            @update_page="(val )=>{page = val}"

          ></Table>
          <!-- Form -->
          
        </v-col>
      </v-row>
      <v-dialog v-model="dialog_form" max-width="600px">

      <CouponForm
            v-if="dialog_form"
            newItemLabel="Coupon"
            :isNew="isNew"
            :api="api"
            :page="page"
            @dialogForm="dialog_form = false"
          ></CouponForm>
          </v-dialog>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isNew: true,
        dialog_form: false,
        filter:'coupon',
        page:1,
        api: {
          filter:'coupons_filter',
          getAll: "coupons_filter",
          create: "insert_coupon",
          delete: "candidate?candidate_id",
        },
        create: true,
        del: false,
        edit: false,
        title: "All Coupons",
        headers: [
          {
            text: "#",
            align: "start",
            sortable: true,
            value: "id",
          },
          {
            text: "code",
            value: "code",
          },
          {
            text: "order id",
            value: "order_id",
          },{
            text: "value",
            value: "value",
          },
          {
            text: "status",
            value: "status",
          },
          {
            text: "customer_page",
            value: "customer_id",
          },
          {
            text: "created",
            value: "created_at",
          }
          // {
          //   text: "",
          //   value: "actions",
          //   sortable: false,
          // },
        ],
      };
    },
    methods: {
      setForm(val) {

        let form = {
          value: null,
        };
        this.$store.dispatch("initForm", form);
        if (val != null) {
          this.isNew = false;
          this.$store.dispatch("setForm", val);
        } else {
          this.isNew = true;
        }
        this.dialog_form = true;
      },
    },
     mounted() {
      this.setForm
     }
  };
  </script>
  
<template>
    <v-container fluid class="icons-page">

        <!-- <v-card class="mx-auto mt-6 mb-5">
            <v-container>
                <v-row>
                    <v-col>
                        <form @submit="match">
                            <v-container fluid class="icons-page">

                                <v-row class="pa-10">
                                    <v-col class="col-4">
                                        <Input
                                                label="name"
                                                type="text"
                                                :model="formAddOperation.name"
                                                @changeValue=" (val) => {formAddOperation.name = val}"
                                        ></Input>
                                    </v-col>
                                    <v-col class="col-4">
                                        <Input
                                                label="code"
                                                type="text"
                                                aria-required="true"
                                                :model="formAddOperation.code"
                                                @changeValue=" (val) => {formAddOperation.code = val}"
                                        ></Input>
                                    </v-col>
                                    <v-col class="col-4">
                                        <Input
                                                label="symbol"
                                                type="text"
                                                aria-required="true"
                                                :model="formAddOperation.symbol"
                                                @changeValue=" (val) => {formAddOperation.symbol = val}"
                                        ></Input>
                                    </v-col>


                                </v-row>

                                <v-row class="pa-10">
                                    <v-col class="col-4">
                                        <Input
                                                label="message ar"
                                                type="text"
                                                :model="formAddOperation.message_ar"
                                                @changeValue=" (val) => {formAddOperation.message_ar = val}"
                                        ></Input>
                                    </v-col>
                                    <v-col class="col-4">
                                        <Input
                                                label="message en"
                                                type="text"
                                                aria-required="true"
                                                :model="formAddOperation.message_en"
                                                @changeValue=" (val) => {formAddOperation.message_en = val}"
                                        ></Input>
                                    </v-col>
                                    <v-col class="col-4">
                                        <Input
                                                label="icon"
                                                type="text"
                                                aria-required="true"
                                                :model="formAddOperation.icon"
                                                @changeValue=" (val) => {formAddOperation.icon = val}"
                                        ></Input>
                                    </v-col>
                                </v-row>


                                <v-row class="pa-10">
                                    <v-col class="col-4">
                                        <Input
                                                label="min"
                                                type="number"
                                                :model="formAddOperation.min"
                                                @changeValue=" (val) => {formAddOperation.min = val}"
                                        ></Input>
                                    </v-col>
                                    <v-col class="col-4">
                                        <Input
                                                label="avg"
                                                type="number"
                                                aria-required="true"
                                                :model="formAddOperation.avg"
                                                @changeValue=" (val) => {formAddOperation.avg = val}"
                                        ></Input>
                                    </v-col>
                                    <v-col class="col-4">
                                        <Input
                                                label="max"
                                                type="number"
                                                aria-required="true"
                                                :model="formAddOperation.max"
                                                @changeValue=" (val) => {formAddOperation.max = val}"
                                        ></Input>
                                    </v-col>
                                </v-row>

                                <v-row class="pa-10">
                                    <v-col class="col-4">
                                        <Input
                                                label="details ar"
                                                type="text"
                                                :model="formAddOperation.details_ar"
                                                @changeValue=" (val) => {formAddOperation.details_ar = val}"
                                        ></Input>
                                    </v-col>
                                    <v-col class="col-4">
                                        <Input
                                                label="details en"
                                                type="text"
                                                aria-required="true"
                                                :model="formAddOperation.details_en"
                                                @changeValue=" (val) => {formAddOperation.details_en = val}"
                                        ></Input>
                                    </v-col>
                                    <v-col class="col-4">
                                        <Input
                                                label="rate to sp"
                                                type="number"
                                                aria-required="true"
                                                :model="formAddOperation.rate_to_sp"
                                                @changeValue=" (val) => {formAddOperation.rate_to_sp = val}"
                                        ></Input>
                                    </v-col>

                                </v-row>
                                <v-row class="pa-10">
                                    <v-col class="col-3">
                                        <Input
                                                label="tax lower paypal"
                                                type="number"
                                                :model="formAddOperation.tax_lower_paypal"
                                                @changeValue=" (val) => {formAddOperation.tax_lower_paypal = val}"
                                        ></Input>
                                    </v-col>
                                   
                                    <v-col class="col-3">
                                        <Input
                                                label="fee lower paypal"
                                                type="number"
                                                aria-required="true"
                                                :model="formAddOperation.fee_lower_paypal"
                                                @changeValue=" (val) => {formAddOperation.fee_lower_paypal = val}"
                                        ></Input>
                                    </v-col>
                                    <v-col class="col-3">
                                        <Input
                                                label="fee upper paypal"
                                                type="number"
                                                aria-required="true"
                                                :model="formAddOperation.fee_upper_paypal"
                                                @changeValue=" (val) => {formAddOperation.fee_upper_paypal = val}"
                                        ></Input>
                                    </v-col>
                                </v-row>


                                <v-row class="pa-10">
                                    <v-col class="col-3">
                                        <Input
                                                label="tax lower wise"
                                                type="number"
                                                :model="formAddOperation.tax_lower_wise"
                                                @changeValue=" (val) => {formAddOperation.tax_lower_wise = val}"
                                        ></Input>
                                    </v-col>
                                   
                                    <v-col class="col-3">
                                        <Input
                                                label="fee lower wise"
                                                type="number"
                                                aria-required="true"
                                                :model="formAddOperation.fee_lower_wise"
                                                @changeValue=" (val) => {formAddOperation.fee_lower_wise = val}"
                                        ></Input>
                                    </v-col>
                                    <v-col class="col-3">
                                        <Input
                                                label="fee upper wise"
                                                type="number"
                                                aria-required="true"
                                                :model="formAddOperation.fee_upper_wise"
                                                @changeValue=" (val) => {formAddOperation.fee_upper_wise = val}"
                                        ></Input>
                                    </v-col>
                                </v-row>


                                <v-row class="pa-10">
                                    <v-col class="col-12 ">

                                        <v-btn type="submit" block class="mr-4 " color="primary">
                                            create
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </form>
                    </v-col>
                </v-row>
            </v-container>
        </v-card> -->

        <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
            <v-col>
                <!-- List -->
                <Table
                        newItemLabel="currency"
                        :filter="filter"
                        :title="title"
                        :api="api"
                        :create="create"
                        :del="del"
                        :edit="edit"
                        :edit_rate_to_sy="edit_rate_to_sy"
                        :headers="headers"
                        @openForm="setForm"
                        @RowClick="rowClick"

                ></Table>
                <!-- Form -->

            </v-col>
        </v-row>
        <v-dialog v-model="dialog_form" max-width="700px">

            <CurrencyForm
                v-if="dialog_form"
                newItemLabel="Currency"
                :isNew="isNew"
                :api="api"
                @dialogForm="dialog_form = false"
            ></CurrencyForm>
        </v-dialog>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                
                isNew: true,
                dialog_form: false,
                api: {
                    getAll: "currencies_page",
                    create: "currency",
                    editRateToSy: "currencies/update_rate_to_sy",
                    edit: "currency_edit",
                },
                create: false,
                del: true,
                edit: true,
                edit_rate_to_sy: true,
                filter: "currencies",
                title: "all currencies",
                
                headers: [
                    {
                        text: "#",
                        align: "start",
                        sortable: true,
                        value: "id",
                    },

                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                    },
                    {
                        text: "name",
                        value: "name",
                    },
                    {
                        text: "symbol",
                        value: "symbol",
                    },
                    {
                        text: "code",
                        value: "code",
                    },
                    {
                        text: "Duration Paypal",
                        value: "duration_paypal",
                    },
                    {
                        text: "Duration wise",
                        value: "duration_wise",
                    },
                    {
                        text: "details ar",
                        value: "details_ar",
                    },
                    {
                        text: "details bank en",
                        value: "details_en",
                    },
                    {
                        text: "details bank ar",
                        value: "details_bank_ar",
                    },
                    {
                        text: "details en",
                        value: "details_bank_en",
                    },
                    {
                        text: "avg",
                        value: "avg",
                    }, {
                        text: "rate_to_sp",
                        value: "rate_to_sp",
                    },
                    {
                        text: "min",
                        value: "min",
                    },
                    {
                        text: "max",
                        value: "max",
                    },
                    {
                        text: "tax_lower_paypal",
                        value: "tax_lower_paypal",
                    },
                    {
                        text: "tax_lower_wise",
                        value: "tax_lower_wise",
                    },
                    {
                        text: "fee_upper_wise",
                        value: "fee_upper_wise",
                    },
                    {
                        text: "fee_lower_wise",
                        value: "fee_lower_wise",
                    },
                    {
                        text: "fee_lower_paypal",
                        value: "fee_lower_paypal",
                    },
                    {
                        text: "fee_upper_paypal",
                        value: "fee_upper_paypal",
                    },
                ],
            };
        },
        methods: {
            rowClick(val) {
                this.$router.push('/currency/' + val.item.id)
            },
            setForm(val) {
                console.log(val);
                let form = {
                    id : null,
                    name : null,
                    code : null,
                    symbol : null,
                    duration_paypal : null,
                    duration_wise : null,
                    icon : null,
                    min : null,
                    max : null,
                    avg : null,
                    details_ar : null,
                    details_en : null,
                    details_bank_ar : null,
                    details_bank_en : null,
                    rate_to_sp : null,
                    tax_lower_wise : null,
                    fee_lower_wise : null,
                    fee_upper_wise : null,
                    tax_lower_paypal : null,
                    fee_lower_paypal : null,
                    fee_upper_paypal : null,
                };
                this.$store.dispatch("initForm", form);
                if (val != null) {
                    this.isNew = false;
                    this.$store.dispatch("setForm", val);
                } else {
                    this.isNew = true;
                }
                this.dialog_form = true;
            },
        },
        //  mounted() {
        //   this.$store.dispatch('initForm', this.form)
        //  }
    };
</script>

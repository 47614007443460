<template>
  <v-expansion-panels
    accordion
    flat
    class="primary"
    tile
    v-model="panel"
    :readonly="readonly"
  >
    <v-expansion-panel>
      <v-expansion-panel-header
        class="white--text font-weight-bold"
        color="primary"
      >
        <v-icon
          small
          color="white"
          aria-hidden="false"
          class="me-2 flex-grow-0"
        >
          mdi-filter
        </v-icon>
        Filter
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card class="" color="white">
          <v-card-text class="d-flex justify-space-between align-center">
            <div class="d-flex justify-space-between align-center">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <Input
                      label="customer id"
                      attr="customer_id"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'customer_id', value: val });
                        }
                      "
                    ></Input
                  ></v-col>
                  <v-col cols="12" sm="6" md="6">
                    <Input
                      label="bill id"
                      attr="id"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'id', value: val });
                        }
                      "
                    ></Input
                  ></v-col>
                  <v-col cols="12" sm="6" md="6">
                    <Input
                      label="telephone"
                      attr="telephone"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'telephone', value: val });
                        }
                      "
                    ></Input
                  ></v-col>
                  <v-col cols="12" sm="6" md="6">
                    <Input
                      label="provider"
                      attr="provider"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'provider', value: val });
                        }
                      "
                    ></Input
                  ></v-col>
                  <v-col cols="12" sm="6" md="6">
                    <Input
                      label="currency"
                      attr="currency"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'currency', value: val });
                        }
                      "
                    ></Input
                  ></v-col>
                  
                 
                  
                  
                </v-row>
              </v-container>
            </div>

            <!-- <RadioButtonsGroup
       label="نوع العقار"
       attr="estate_type"
       :radioGroup="radioGroup"
       @radioButton="updateFilter"></RadioButtonsGroup> -->
          </v-card-text>
          <v-btn class="ma-2" outlined color="indigo" @click="ResetFilter()">
            Reset Filter
          </v-btn>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    api: Object,
    per_page: Number,
    page: Number,
    search: {},
  },
  data() {
    return {
      panel: [0],
      tempData: "",
      DataAfterFiltring: [],
      //for callapse
      readonly: false,
      status_select: [
        { text: " لم يتم تثبيت أكتمال عملية الدفع ", value: 'لم يتم تثبيت أكتمال عملية الدفع' },
        { text: " قيد معالجة الطلب ", value: 'قيد معالجة الطلب' },
        { text: " تمت العملية بنجاح ", value: 'تمت العملية بنجاح' },
      ],
      //
      filter_init_value: {},
    };
  },
  components: {},
  watch: {
    search(val) {
      this.updateFilter(val);
    },
  },
  computed: {
    ...mapGetters(["getFilter", "getContractTypes", "getLocations"]),
    contractTypes() {
      return this.getContractTypes;
    },
    Locations() {
      return this.getLocations;
    },
  },
  methods: {
    ...mapActions([
      "createFilter",
      "initFilter",
      "fetchTableDataFilter",
      "fetchLocations",
    ]),
    updateFilter(val) {
      this.createFilter(val).then(() => {
        this.fetchTableDataFilter({
          api: this.api,
          page: this.page,
          per_page: this.per_page,
        });
      });
    },
    ResetFilter() {
      window.location.reload();
    },
  },
  mounted() {
    this.initFilter(this.filter_init_value);
  },
};
</script>

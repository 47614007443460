<template>
  <VueApexCharts :series="series" :options="options"></VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    series: Array
  },
  components: {
    VueApexCharts,
  },
  data: function () {
    return {
      options: {
        chart: {
          type: "bar",
        },
      },
    };
  },
};
</script>

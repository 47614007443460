var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',_vm._l((_vm.fields),function(field,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6","md":"6"}},[(field.type == 'switch' )?_c('Switcher',{attrs:{"attr":field.name,"label":field.name,"model":_vm.form[`${field.key}`]},on:{"switcher":(val) => {
                  _vm.form[`${field.key}`] = val.value;
                }}}):(field.type == 'select' )?_c('FormSelect',{attrs:{"items":field.items,"attr":field.name,"label":field.name,"model":_vm.form[`${field.key}`]},on:{"select":(val) => {
                  _vm.form[`${field.key}`] = val.value;
                }}}):_c('Input',{attrs:{"label":field.name,"type":"text","required":"","model":_vm.form[`${field.key}`]},on:{"changeValue":(val) => {
                  _vm.form[`${field.key}`] = val;
                }}})],1)}),1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('Button',{attrs:{"color":"blue darken-1","type":"submit","label":"Save"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"Name in Arabic","type":"text","model":_vm.form.name_ar,"errorMessages":_vm.name_arErrors},on:{"changeValue":(val) => {
                  _vm.form.name_ar = val;
                  _vm.$v.form.name_ar.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('Input',{attrs:{"label":"Name in English","type":"text","model":_vm.form.name_en,"errorMessages":_vm.name_enErrors},on:{"changeValue":(val) => {
                  _vm.form.name_en = val;
                  _vm.$v.form.name_en.$touch();
                }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('Input',{attrs:{"label":"Code","type":"text","model":_vm.form.code,"errorMessages":_vm.codeErrors},on:{"changeValue":(val) => {
                  _vm.form.code = val;
                  _vm.$v.form.code.$touch();
                }}})],1),(_vm.passwordChange)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('File',{attrs:{"label":"Image","type":"file","model":_vm.form.img,"errorMessages":_vm.imgErrors},on:{"changeValue":(val) => {
                  _vm.form.img = val;
                  _vm.$v.form.img.$touch();
                }}})],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('Button',{attrs:{"color":"blue darken-1","type":"submit","label":"Save"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-container fluid class="icons-page">
      <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
        <v-col>
          <!-- List -->
          <Table
            newItemLabel="All Messages"
            :filter="filter"
            :title="title"
            :api="api"
            :create="create"
            :del="del"
            :edit="edit"
            :headers="headers"
            @openForm="setForm"
            @update_page="(val )=>{page = val}"

          ></Table>
          <!-- Form -->
          
        </v-col>
      </v-row>
      <v-dialog v-model="dialog_form" max-width="500px">

<GeneralForm
      v-if="dialog_form"
      :fields="fields"
      newItemLabel="messages"
      :isNew="isNew"
      :page="page"
      :api="api"
      @dialogForm="dialog_form = false"

    ></GeneralForm>
    </v-dialog>
    </v-container>
    
  </template>
  
  <script>
  export default {
    data() {
      return {
        isNew: true,
        dialog_form: false,
        page:1,
        api: {
          getAll: "Messages",
          create: "Messages",
          delete: "Messages",
          edit: "Messages",
        },
        create: true,
        del: true,
        edit: true,
        filter: "Messages",
        title: "All Messages",
        fields: [
         
          {
            name: "text",
            key: "text",
          },
          {
            
            name: "category id",
            key: "category_id",
          }
          
        ],
        headers: [
          {
            text: "#",
            align: "start",
            sortable: true,
            value: "id",
          },
          {
            text: "text",
            value: "text",
          },
          {
            text: "order_count",
            value: "order_count",
          },
          {
            text: "category",
            value: "category.name",
          },
          {
            text: "",
            value: "actions",
            sortable: false,
          },
        ],
      };
    },
    methods: {
      setForm(val) {
        console.log(val);
        let form = {
         
           text:val.text,
        
          category_id:val.category.id,
          
          type_id:1,
          
        };
        this.$store.dispatch("initForm", form);
        if (val != null) {
          this.isNew = false;
          this.$store.dispatch("setForm", form);
        } else {
          this.isNew = true;
        }
        this.dialog_form = true;
      },
    },
    //  mounted() {
    //   this.$store.dispatch('initForm', this.form)
    //  }
  };
  </script>
  
<template>
    <v-container fluid class="icons-page">
        <v-card class="mx-auto ">
            <v-container>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-list-item>
                            <v-list-item-avatar size="100">
                                <v-avatar>
                                    <span class="white--text text-h5"><v-icon>mdi-wallet</v-icon></span>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="title">{{item.name}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-list-item class="mx-5">
                            <v-list-item-content>
                                <v-list-item-body style="margin-top:20px;"><b>Amount Now :</b> {{item.amount}}
                                </v-list-item-body>
                                <v-list-item-body style="margin-top:5px;"><b>Profits :</b>{{item.profits}}
                                </v-list-item-body>
                                <v-list-item-body style="margin-top:5px;"><b>Total :</b>{{item.total}}
                                </v-list-item-body>
                                <v-list-item-body style="margin-top:5px;"><b>Created At :</b>{{formatDate(item.created_at)}}
                                </v-list-item-body>
                                <v-list-item-body style="margin-top:5px;"><b>Last Seen :</b>{{formatDate(item.updated_at)}}
                                </v-list-item-body>
                                <v-list-item-body style="margin-top:5px;"><b>id :</b>#{{item.id}}</v-list-item-body>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <form @submit="match">
                            <v-container fluid class="icons-page">

                                <v-row class="pa-10">
                                    <v-col class="col-5">
                                        <Input
                                                label="إضافة مبلغ"
                                                type="number"
                                                :model="formAddOperation.amount"
                                                @changeValue=" (val) => {formAddOperation.amount = val}"
                                        ></Input>
                                    </v-col>
                                    <v-col class="col-5">
                                        <Input
                                                label="تاريخ الإيداع"
                                                type="date"
                                                aria-required="true"
                                                :model="formAddOperation.date"
                                                @changeValue=" (val) => {formAddOperation.date = val}"
                                        ></Input>
                                    </v-col>

                                    <v-col class="col-2">

                                        <v-btn type="submit" block class="mr-4" color="primary">
                                            edit
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </form>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>

        </v-card>
        <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2  ">
            <v-col>
                <v-tabs v-model="tab" align-with-title hidden>
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab> All Operations</v-tab>
                    <v-tab>Bills Orders</v-tab>
                    <v-tab>Islamic Orders</v-tab>
                    <v-tab>Money Orders</v-tab>
                </v-tabs>
                <v-tabs-items class="mt-5" v-model="tab">
                    <v-tab-item>
                        <!-- List -->
                        <Table
                                v-if="tab == 0"
                                newItemLabel="All Operations"
                                :filter="filter"
                                title="All Operations"
                                :api="api"
                                :create="create"
                                :del="del"
                                :edit="edit"
                                :resend="resend"
                                :message="message"
                                :exchange_sim="exchange_sim"
                                :headers="headers"
                                @openForm="setForm"
                                @update_page="(val )=>{page = val}"

                        ></Table>
                        <!-- Form -->
                    </v-tab-item>
<!--
                    <v-tab-item>
                        &lt;!&ndash; List &ndash;&gt;
                        <Table
                                v-if="tab == 1"
                                newItemLabel="Bills Orders"
                                :filter="filter"
                                title="Bills Orders"
                                :api="api_bills"
                                :create="create"
                                :del="del"
                                :edit="edit"
                                :headers="headers_bills"
                                @openForm="setForm"
                        ></Table>
                        &lt;!&ndash; Form &ndash;&gt;
                    </v-tab-item>

                  -->
                </v-tabs-items>

            </v-col>
        </v-row>

        <v-dialog v-model="dialog_order_form" max-width="800px">
            <OrderForm
                    v-if="dialog_order_form"
                    :fields="fields"
                    newItemLabel="Order"
                    :isNew="isNew"
                    :id="id"
                    :page="page"
                    :api="api"
                    @dialogForm="dialog_order_form = false"
            ></OrderForm>
        </v-dialog>

    </v-container>

</template>

<script>
    export default {
        data() {
            return {
                formAddOperation: {
                    id: null,
                    amount: 1,
                    date: null
                },
                isNew: true,
                dialog_order_form: false,
                tab: 0,
                page: 1,
                api: {
                    create: "add_amount_to_wallet",
                    getAll: "operations?wallet_id=" + this.$route.params.id,
                    resend: "PayingButNotReceive",
                    message: "PayingJustSend",
                    exchange_sim: "changeTypeofSIM",
                    edit: "updateOrder",
                },
                create: false,
                del: false,
                edit: true,
                resend: true,
                message: true,
                exchange_sim: true,

                filter: "Orders",
                title: "All Customers",
                fields: [
                    {
                        name: "Active",
                        key: "active",
                        type: "switch",
                    },
                    {
                        name: "Number",
                        key: "beneficiary_no",
                        type: "input",
                    },
                    {
                        name: "status",
                        key: "status",
                        type: "select",
                        items: [
                            {text: "تمت العملية بنجاح", value: "تمت العملية بنجاح"},
                            {text: "قيد معالجة الطلب", value: "قيد معالجة الطلب"},
                            {text: "لم يتم الدفع", value: "لم يتم الدفع"},
                        ],
                    },

                    {
                        name: "Customer id",
                        key: "customer_id",
                        type: "input",
                    },
                    {
                        name: "Message template",
                        key: "message_template",
                        type: "input",
                    },
                    {
                        name: "Paymentmethod",
                        key: "payment_method",
                        type: "input",
                    },
                    {
                        name: "Price id",
                        key: "price_id",
                        type: "input",
                    },
                    {
                        name: "Rest",
                        key: "rest",
                        type: "input",
                    },
                    {
                        name: "is send message ?",
                        key: "send_message",
                        type: "switch",
                    },
                    {
                        name: "type",
                        key: "type_id",
                        type: "input",
                    },
                    {
                        name: "on Time",
                        key: "on_time",
                        type: "input",
                    },
                    {
                        name: "response",
                        key: "response",
                        type: "input",
                    },
                ],
                headers: [
                    {
                        text: "#",
                        align: "start",
                        sortable: true,
                        value: "id",
                    },
                    {
                        text: "",
                        value: "operation",
                        sortable: false,
                    },

                    {
                        text: "amount",
                        value: "amount",
                    },
                    {
                        text: "description",
                        value: "description",
                    },
                    {
                        text: "status",
                        value: "status",
                    }, {
                        text: "order id",
                        value: "order_id",
                    },
                    {
                        text: "type",
                        value: "type",
                    },
                    {
                        text: "date",
                        value: "date",
                    },
                    {
                        text: "created at",
                        value: "created_at",
                    }
                ],

            };
        },
        computed: {
            item() {
                return this.$store.getters.getItem;
            },
        },
        methods: {
            formatDate(val, on_time) {
                var data = new Date(val);
                var string = data.getUTCFullYear() +
                    "/" +
                    (data.getUTCMonth() + 1) +
                    "/" +
                    data.getDate();

                if (on_time == null) {
                    string += " " + data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds()

                }
                return (string);

            },
            setForm(val) {
                this.id = val.id;
                let form = {
                    send_message: null,
                    type_id: null,
                    rest: null,
                    price_id: null,
                    status: null,
                    payment_method: null,
                    message_template: null,
                    customer_id: null,
                    country: null,
                    character_currency: null,
                    currency: null,
                    beneficiary_no: null,
                    active: null,
                };
                this.$store.dispatch("initForm", form);
                if (val != null) {
                    this.isNew = false;
                    this.$store.dispatch("setForm", val);
                } else {
                    this.isNew = true;
                }
                this.dialog_order_form = true;
            },
            addAmount() {
                console.log(this.formAddOperation)
            },
            match() {
                this.formAddOperation.id = this.item.id
                let formdata = new FormData();
                for (let f in this.form) {
                    formdata.append(f, this.form[f]);
                }

                this.$store.dispatch("sendForm", {
                    api: this.api,
                    form: this.formAddOperation,
                    isNew: true,
                });
                this.$store.dispatch(
                    "fetchItem",
                    "wallet/" + this.$route.params.id
                );
            },
        },
        mounted() {
            this.$store.dispatch(
                "fetchItem",
                "wallet/" + this.$route.params.id
            );
        },
    };
</script>

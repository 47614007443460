var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-stepper',{model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('v-stepper-header',[_vm._l((_vm.steps),function(n){return [_c('v-stepper-step',{key:`${n}-step`,attrs:{"complete":_vm.e1 > n,"step":n,"editable":""}},[_vm._v(" Step "+_vm._s(n)+" ")]),(n !== _vm.steps)?_c('v-divider',{key:n}):_vm._e()]})],2),_c('v-stepper-items',[_c('v-stepper-content',{key:"1-content",attrs:{"step":"1"}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('Input',{attrs:{"label":"Currency Name","type":"text","model":_vm.form.name},on:{"changeValue":(val) => {
                        _vm.form.name = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('Input',{attrs:{"label":"Currency Code","type":"text","model":_vm.form.code},on:{"changeValue":(val) => {
                        _vm.form.code = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('Input',{attrs:{"label":"Currency Symbol","type":"text","model":_vm.form.symbol},on:{"changeValue":(val) => {
                        _vm.form.symbol = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('TextArea',{attrs:{"label":"PayPal Arabic Message","type":"text","model":_vm.duration_paypal_ar},on:{"changeValue":(val) => {
                        _vm.duration_paypal_ar = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('TextArea',{attrs:{"label":"PayPal English Message","type":"text","model":_vm.duration_paypal_en},on:{"changeValue":(val) => {
                        _vm.duration_paypal_en = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('TextArea',{attrs:{"label":"wise Arabic Message","type":"text","model":_vm.duration_wise_ar},on:{"changeValue":(val) => {
                        _vm.duration_wise_ar = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('TextArea',{attrs:{"label":"wise English Message","type":"text","model":_vm.duration_wise_en},on:{"changeValue":(val) => {
                        _vm.duration_wise_en = val;
                      }}})],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.nextStep(1)}}},[_vm._v(" Continue ")]),_c('v-btn',{attrs:{"text":""}},[_vm._v(" Cancel ")])],1),_c('v-stepper-content',{key:"2-content",attrs:{"step":"2"}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('Input',{attrs:{"label":"Min","type":"text","model":_vm.form.min},on:{"changeValue":(val) => {
                        _vm.form.min = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('Input',{attrs:{"label":"Max","type":"text","model":_vm.form.max},on:{"changeValue":(val) => {
                        _vm.form.max = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('Input',{attrs:{"label":"Avg","type":"text","model":_vm.form.avg},on:{"changeValue":(val) => {
                        _vm.form.avg = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('TextArea',{attrs:{"label":"Details Arabic","type":"text","model":_vm.form.details_ar},on:{"changeValue":(val) => {
                        _vm.form.details_ar = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('TextArea',{attrs:{"label":"Details English","type":"text","model":_vm.form.details_en},on:{"changeValue":(val) => {
                        _vm.form.details_en = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('TextArea',{attrs:{"label":"Details Bank Arabic","type":"text","model":_vm.form.details_bank_ar},on:{"changeValue":(val) => {
                        _vm.form.details_bank_ar = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('TextArea',{attrs:{"label":"Details Bank English","type":"text","model":_vm.form.details_bank_en},on:{"changeValue":(val) => {
                        _vm.form.details_bank_en = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('Input',{attrs:{"label":"Rate to Sp","type":"text","model":_vm.form.rate_to_sp},on:{"changeValue":(val) => {
                        _vm.form.rate_to_sp = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('Input',{attrs:{"label":"Tax Lower Wise","type":"text","model":_vm.form.tax_lower_wise},on:{"changeValue":(val) => {
                        _vm.form.tax_lower_wise = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('Input',{attrs:{"label":"Fee Lower Wise","type":"text","model":_vm.form.fee_lower_wise},on:{"changeValue":(val) => {
                        _vm.form.fee_lower_wise = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('Input',{attrs:{"label":"Fee Upper Wise","type":"text","model":_vm.form.fee_upper_wise},on:{"changeValue":(val) => {
                        _vm.form.fee_upper_wise = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('Input',{attrs:{"label":"Fee Lower Paypal","type":"text","model":_vm.form.fee_lower_paypal},on:{"changeValue":(val) => {
                        _vm.form.fee_lower_paypal = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('Input',{attrs:{"label":"Fee Upper Paypal","type":"text","model":_vm.form.fee_upper_paypal},on:{"changeValue":(val) => {
                        _vm.form.fee_upper_paypal = val;
                      }}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('Input',{attrs:{"label":"Icon","type":"text","model":_vm.form.icon},on:{"changeValue":(val) => {
                        _vm.form.icon = val;
                      }}})],1)],1),_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" save ")]),_c('v-btn',{attrs:{"text":""}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
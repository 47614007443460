<template>
    <v-dialog
            class="rounded-0 bg-white darken-2"
            v-model="notify"
            max-width="550"
    >
        <v-card>
            <v-card-title class="text--h6" :style="'color:'+color">{{ title }}</v-card-title>
            <v-list-item three-line>
                <v-container fluid class="icons-page">

                    <v-row class="pa-10">
                        <v-col class="col-10">
                            <Input
                                    label="سعر الدولار للمحاسبة"
                                    type="number"
                                    :model="USDRateToSp.rate_to_sp"
                                    @changeValue=" (val) => {newUSDRateToSp = val}"
                            ></Input>
                        </v-col>

                    </v-row>
                </v-container>
            </v-list-item>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="confirmDialog(true,newUSDRateToSp)"
                >Yes
                </v-btn
                >
                <v-btn color="blue darken-1" text @click="confirmDialog(false)"
                >Cancel
                </v-btn
                >

                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";

    export default {
        props: {
            isPopup: {
                default: false,
            },
            color: {
                default: 'black'
            },
            model: {
                default: []
            },
            title: {
                default: "",
            },
            description: {
                default: "",
            },
        },
        computed: {
            ...mapGetters(["getForm"]),
            form() {
                return this.getForm;
            },
        },
        data() {
            return {
                formAddOperation: {
                    id: null,
                    amount: 1,
                    date: null
                },
                notify: false,
                USDRateToSp: false,
                newUSDRateToSp: 0,
            };
        },
        watch: {
            isPopup() {
                this.notify = true;
            },
        },
        methods: {
            items() {
                var items = this.$store.getters.getItems;
                var data = [];

                items.forEach(function (element) {
                    data.push(element.name)
                });
                this.USDRateToSp = items.find(obj => obj.code === "USD");


                return data;
            },
            confirmDialog(val,newUSDRateToSp) {
                if (val) {
                    this.$emit("confirmDialog",newUSDRateToSp);
                }
                this.notify = false;
            },
        },
        mounted() {
            this.$store.dispatch(
                "fetchItems",
                "currencies"
            ).then((res) => {
                this.items()[0]
            });

        }
    };
</script>

import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth'
import Table from './modules/table'
import filter from './modules/filter'
import Form from './modules/form'
import CustomerProfile from './modules/lists/customer-profile'
import dashboard from './modules/lists/dashboard'
import message from './modules/lists/message'
import money from './modules/lists/money'
import paypal_response from './modules/lists/paypal-response'

Vue.use(Vuex);

export default new Vuex.Store({

  namespace: true,
  state: {
    drawer: false
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    }
  },
  actions: {
    TOGGLE_DRAWER({ commit }) {
      commit('toggleDrawer');
    }
  },
  getters: {
    DRAWER_STATE(state) {
      return state.drawer;
    }
  },
    modules: {
    auth,
    Table,
    filter,
    Form,
    CustomerProfile,
    dashboard,
    message,
    money,
    paypal_response
  }
});

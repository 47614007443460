import axios from "axios";
const state = () => ({
  MessageCategory: [],
  SupportMessage:[]

});

const actions = {
  async fetchSupportMessages({ commit }) {
    await axios
      .get(`get-feedback`)
      .then((res) => {
        let resultData = res.data.data;
        commit("SET_SUPPORT_MESSAGES", resultData);
      })
      .catch((err) => {
        commit("SET_SUPPORT_MESSAGES", err);
      });
    },
  async fetchMessages({ commit }, api_info) {
    await axios
      .get(`${api_info.one}`)
      .then((res) => {
        let resultData = res.data.data;
        commit("SET_MESSAGES", resultData);
      })
      .catch((err) => {
        commit("SET_MESSAGES", err);
      });
    },
      async fetchCategoryMessages({ commit }, api_info) {
        await axios
          .get(`${api_info.one}`)
          .then((res) => {
            let resultData = res.data.data;
            commit("SET_MESSAGES", resultData);
          })
          .catch((err) => {
            commit("SET_MESSAGES", err);
          });
    
        } 

};

const getters = {
 
  getSupportMessages: (state) => {
    return state.SupportMessage;
  },
};

const mutations = {
  SET_MESSAGES(state, payload) {
    state.MessageCategory = payload;
  },
  SET_SUPPORT_MESSAGES(state, payload) {
    state.SupportMessage = payload;
  },
  
};

export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
 <h5 class="ma-2 textColor--text">{{title}}</h5>
</template>
<script>
export default {
   props:{
       title:String
   } 
}
</script>

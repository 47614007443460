import axios from "axios";
const state = () => ({
  data: {},
  loading:false
});

const actions = {

  async fetchSearchResponse({ commit },params) {
    commit("SET_LOADING", true);
    commit("SET_DATA", {});

    await axios
      .get(`ResponceSearch?type=`+params.type+`&paypal_key=`+params.paypal_key)
      .then((res) => {
        var resultData = res.data
        console.log(resultData);
       
          commit("SET_DATA", resultData);
          commit("SET_LOADING", false);

      })
      .catch((err) => {
        commit("SET_SUPPORT_MESSAGES", err);
      });
    },
 
    

};

const getters = {
  getResponseData: (state) => {
    return state.data;
  },
  getLoadingData: (state) => {
    return state.loading;
  },
};

const mutations = {
    SET_DATA(state, payload) {
    state.data = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  
};

export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
    <v-container fluid class="icons-page">
        <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
            <v-col>
                <!-- List -->
                <Table
                        newItemLabel="sms has been sent"
                        :filter="filter"
                        :title="title"
                        :api="api"
                        :create="create"
                        :del="del"
                        :edit="edit"
                        :headers="headers"
                        @openForm="setForm"
                ></Table>
                <!-- Form -->

            </v-col>
        </v-row>
       
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                isNew: true,
                dialog_form: false,
                api: {
                    getAll: "sms_responses",
                },
                create: false,
                del: false,
                edit: false,
                filter: "Orders",
                title: "all sms",
                
                headers: [
                    {
                        text: "#",
                        align: "start",
                        sortable: true,
                        value: "id",
                    },
                    {
                        text: "number",
                        value: "number",
                    },

                    {
                        text: "message",
                        value: "message",
                    },
                    {
                        text: "result",
                        value: "result",
                    },
                    {
                        text: "created_at",
                        value: "created_at",
                    },
                ],
            };
        },
    
    };
</script>
  
<template>

  <v-row class="chat">
    <v-col cols="4" lg="4" sm="12" md="4">
      <v-row class="pb-1">
        <v-col class="container-sm scrollable-container users-bord">
          <v-list class="mx-5" v-if="showUsers">

            <v-text-field
                v-model="search"
                hide-details
                label="Search Customers"
                prepend-inner-icon="mdi-magnify"
                rounded
                class="pb-2 align-center search-input"

            ></v-text-field>
            <div v-for="user in filteredUsers" :key="user.id">
              <user  :checked="idNow === user.doc.id" v-if="user.show" @click="getMessages(user.doc.id);getCustomerFcmToken(user.userId)"
                    :gender="user.gender" :name="user.name"
                    :id="user.userId"
                    :count-new-message="user.unreadCount.toString()"></user>
            </div>

          </v-list>
        </v-col>
      </v-row >
      <v-list-item-subtitle>Add new chat using user's id. <span v-if="notFoundId" style="color: red">this id user not found</span></v-list-item-subtitle>

      <v-row>

        <v-col class="row pl-5 pt-6">
          <form @submit.prevent="createNewCustomer">
            <v-btn type="submit" color="#144887" :disabled="customerId == '' || customerId == null"
                   class="add-chat col-4 ma-0" small rounded height="50px">add new chat
            </v-btn>
            <input @keydown="notFoundId = false" class="field-id-customer col-8" v-model="customerId" placeholder="Customer ID" required="true"/>

          </form>
        </v-col>

      </v-row>

    </v-col>

    <v-col class="messages" cols="8" lg="8" sm="12" md="8" v-if="idNow != '' && idNow != null && idNow != undefined">
      <v-container ref="containerElement" id="myDiv" class="scrollable-container chat-bord">
        <div class="container-sm mt-20">
          <div class="mx-5 "
               v-if="messages.length && showMessages"
          >
            <Message
                v-for="{ id, message, userPhotoURL, userName, userId,time,isSeen} in messages"
                :key="id"
                :name="userName"
                :photo-url="userPhotoURL"
                :sender="userId === user?.uid"
                :time="time"
                :is-seen="isSeen"
                ref="lastMessage"
            >
              {{ message }}
            </Message>
          </div>
        </div>
      </v-container>

      <div v-if="idNow != '' && idNow != null && idNow != undefined">
        <div class="container">
          <form @submit.prevent="send(customerFcmToken)">
            <v-row class="mb-0 pa-0">
              <v-col cols="10" class="ma-0">
                <input class="message-input" v-model="message" placeholder="Message" required="true"/>
              </v-col>
              <v-col>
                <v-btn class="button-send" height="45" width="90" type="submit">
                  send
                </v-btn>
              </v-col>
            </v-row>

          </form>
        </div>
      </div>


    </v-col>

  </v-row>


</template>
<script>

import {ref, watch, nextTick} from 'vue'
import {useAuth, useChat, getAllUsers, getUserChat, createNewChat} from '@/firebase'

import SendIcon from './SendIcon.vue'
import Message from './Message.vue'
import User from "@/components/Chats/user.vue";
import item from "@/pages/Bank/item.vue";


export default {
  components: {User, Message},
  data() {
    return {
      search: "",
      customerId: "",
      notFoundId: false,
      customerFcmToken : ""
    }
  },
  setup() {

    const {user} = useAuth()
    let {messages, sendMessage} = useChat()

    const bottom = ref(null)
    const showUsers = ref(true)
    const showMessages = ref(true)
    const userNow = ref(null)

    const users = getAllUsers(showUsers)

    watch(
        messages,
        () => {
          nextTick(() => {
            bottom.value?.scrollIntoView({behavior: 'smooth'})
          })
        },
        {deep: true},
    )
    watch(
        users,
        () => {
          nextTick(() => {
            bottom.value?.scrollIntoView({behavior: 'smooth'})
          })
        },
        {deep: true},
    )


    const message = ref('')
    const idNow = ref('')
    const send = (fcm_token) => {

      sendMessage(message.value, idNow.value,fcm_token)
      message.value = ''
    }


    const getMessages = (id) => {
      useChat(id, showMessages)
      idNow.value = id
    }

    return {
      user,
      messages,
      bottom,
      message,
      send,
      users,
      idNow,
      showUsers,
      getMessages,
      showMessages
    }
  },
  methods: {
    getCustomerFcmToken(id){
      this.$store.dispatch(
          "fetchItem",
          "customer_profile?id=" + id
      ).then((res) => {
        if (this.$store.getters.getItem){
          console.log(this.$store.getters.getItem)
          this.customerFcmToken = this.$store.getters.getItem.fcm_token

        }else {
          this.notFoundId = true
          console.log("error")
        }

      }).catch((err) => {
        console.log(err.messages)
      })

    },
    createNewCustomer() {
      this.$store.dispatch(
          "fetchItem",
          "customer_profile?id=" + this.customerId
      ).then((res) => {
        if (this.$store.getters.getItem){
          console.log(this.$store.getters.getItem)
          var customer = this.$store.getters.getItem
          createNewChat(customer.email,customer.id,customer.gender,customer.first_name +" "+ customer.last_name)


        }else {
          this.notFoundId = true
          console.log("error")
        }

      }).catch((err) => {
        console.log(err.messages)
      })

    }
  },
  mounted() {
    //createNewChat("emailtest@gmail.com")
    let script = document.createElement('script');
    script.setAttribute('src', 'https://cdn.tailwindcss.com');
    document.head.appendChild(script);

  },
  updated() {

    if (this.messages.length)
      this.$nextTick(() => {
        this.$vuetify.goTo(this.$refs.lastMessage[this.$refs.lastMessage.length - 1], {container: this.$refs.containerElement});
      });
  },
  computed: {
    filteredUsers() {

      this.users.map(user => {
        user.show = (user.id != null &&( user.id.toString().includes(this.search) || user.name.toLowerCase().includes(this.search)));
      });
      return this.users
    },

  },
}
</script>


<style scoped>
.chat-bord {
  background-color: #f8f7fa;
  margin: 0;
  border-radius: 10px;
  padding: 0;
}


.users-bord {
  padding: 0;
  max-height: 100%;

}

.chat {
  padding-top: 10px;
  padding-right: 10px;

  margin: 0;
}

.messages {
  margin: 0;
  padding: 0;
  padding-left: 10px;
}

.scrollable-container {
  height: 550px;
  max-height: 550px;
  overflow-y: auto;
}

.button-send {
  margin-top: 13px;
  border-radius: 10px;
  align-self: center;

}


.message-input {
  background-color: #d3d3d3;
  width: 100%;
  border-radius: 10px;
  height: 50px;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 10px;
  padding-left: 10px;
}

.add-chat {
  background-color: #144887;
  color: white;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.search-input {
  background-color: #d3d3d3;
  border-radius: 10px;
  height: 50px;
}

.field-id-customer {
  background-color: #d3d3d3;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding-top: 12px;
  padding-bottom: 14.5px;
}

</style>
<template>
  <v-container fluid class="icons-page">
    <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
      <v-col>
        <!-- List -->
        <Table
          newItemLabel="All Enquiry Verified"
          :filter="filter"
          :title="title"
          :api="api"
          :create="create"
          :del="del"
          :show="show"
          :resend="resend"
          :edit="edit"
          :headers="headers"
          @openForm="setForm"
        ></Table>
        <!-- Form -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isNew: true,
      dialog_form: false,
      api: {
        getAll: "bank/payed",
        filter: "bank/payed",
        show: "bank-order/",
        resend: "Islamic/PayButNotReceive",
      },
      create: false,
      del: false,
      edit: false,
      show: true,
      resend: true,
      filter: "bank",
      title: "all bills",
      fields: [
        {
          name: "الحجم",
          key: "size",
        },
        {
          name: "السعر",
          key: "price",
        },
      ],
      headers: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "actions",
          value: "actions",
        },
        {
          text: "",
          value: "operation",
          sortable: false,
        },
        {
          text: "response",
          value: "bank_response.result",
        },
        {
          text: "bank",
          value: "bank.name_ar",
        },
        {
          text: "customer id",
          value: "customer_id",
        },
        {
          text: "code",
          value: "code",
        },
        {
          text: "status",
          value: "status_response",
        },
        {
          text: "bank response",
          value: "result",
        },
        {
          text: "PayPal Account",
          value: "paypal_name",
        },{
          text: "payment method",
          value: "payment_method",
        },
        {
          text: "amount",
          value: "amount",
        },
        {
          text: "amount currency",
          value: "amount_currency",
        },
        {
          text: "currency",
          value: "currency",
        },
        {
          text: "created_at",
          value: "created_at",
        },
        ,
      ],
    };
  },
  methods: {
    setForm(val) {
      let form = {
        size: null,
        price: null,
      };
      this.$store.dispatch("initForm", form);
      if (val != null) {
        this.isNew = false;
        this.$store.dispatch("setForm", val);
      } else {
        this.isNew = true;
      }
      this.dialog_form = true;
    },
  },
  //  mounted() {
  //   this.$store.dispatch('initForm', this.form)
  //  }
};
</script>

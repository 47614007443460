<template>
    <v-container fluid class="icons-page">
      <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
        <v-col>
          <!-- List -->
          <Table
            newItemLabel="All Services"
            :filter="filter"
            :title="title"
            :api="api"
            :create="create"
            :del="del"
            :edit="edit"
            :pay="pay"
            :headers="headers"
            @openForm="setForm"
            @update_page="(val )=>{page = val}"
          ></Table>
          <!-- Form -->
          
        </v-col>
      </v-row>
      <v-dialog v-model="dialog_form" max-width="500px">


        
<BankServiceForm
      v-if="dialog_form"
      newItemLabel="Service"
      :isNew="isNew"
      :id="id"
      :api="api"
      :page="page"
      @dialogForm="dialog_form = false"
    ></BankServiceForm>
    </v-dialog>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isNew: true,
        dialog_form: false,
        id:null,
        page:1,
        api: {
          getAll: "bank-service",
          create: "bank-service",
          edit: "bank-service/",
          delete: "bank-service/",
          pay: "Islamic/make_as_pay"
        },
        create: true,
        del: true,
        edit: true,
        pay: true,
        filter: "Orders",
        title: "all bills",
        form:
          {
            name_ar:null,
            name_en:null,
            code:null,
            img:null 
          },
        headers: [
          {
            text: "#",
            align: "start",
            sortable: true,
            value: "id",
          },
          {
            text: "name_ar",
            value: "name_ar",
          },
          {
            text: "name_en",
            value: "name_en",
          },
          {
            text: "code",
            value: "code",
          },{
            text: "img",
            value: "img",
          },{
            text: "created_at",
            value: "created_at",
          },
         
          {
            text: "",
            value: "actions",
            sortable: false,
          },
        ],
      };
    },
    methods: {
      setForm(val) {
        let form = {
          name_ar:null,
            name_en:null,
            code:null,
            img:null 
        };
        this.$store.dispatch("initForm", form);
        if (val != null) {
          this.isNew = false;
          this.$store.dispatch("setForm", val);
        } else {
          this.isNew = true;
        }
        this.dialog_form = true;
      },
    },
    //  mounted() {
    //   this.$store.dispatch('initForm', this.form)
    //  }
  };
  </script>
  
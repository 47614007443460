<template>
    <v-container fluid class="icons-page">
        <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
            <v-col>
                <!-- List -->
                <Table
                        newItemLabel="System Constant"
                        :filter="filter"
                        :title="title"
                        :api="api"
                        :create="create"
                        :del="del"
                        :edit="edit"
                        :headers="headers"
                        @openForm="setForm"
                ></Table>
                <!-- Form -->

            </v-col>
        </v-row>
        <v-dialog v-model="dialog_form" max-width="500px">

            <GeneralForm
                    v-if="dialog_form"
                    :fields="fields"
                    newItemLabel="Constant System"
                    :isNew="isNew"
                    :api="api"
                    @dialogForm="dialog_form = false"
            ></GeneralForm>
        </v-dialog>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                isNew: true,
                dialog_form: false,
                api: {
                    getAll: "constant",
                    create: "candidates",
                    edit: "update_key",
                },
                create: false,
                del: false,
                edit: true,
                filter: "Orders",
                title: "all Constant",
                fields: [

                    {
                        name: "Key",
                        key: "key",
                    },
                    {
                        name: "Value",
                        key: "value",
                    },
                ],
                headers: [
                    {
                        text: "#",
                        align: "start",
                        sortable: true,
                        value: "id",
                    },

                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                    },
                    {
                        text: "key",
                        value: "key",
                    },

                    {
                        text: "value",
                        value: "value",
                    },
                ],
            };
        },
        methods: {
            setForm(val) {
                console.log(val);
                let form = {
                    key: null,
                    value: null,
                };
                this.$store.dispatch("initForm", form);
                if (val != null) {
                    this.isNew = false;
                    this.$store.dispatch("setForm", val);
                } else {
                    this.isNew = true;
                }
                this.dialog_form = true;
            },
        },
        //  mounted() {
        //   this.$store.dispatch('initForm', this.form)
        //  }
    };
</script>
  
<template>
    <v-container fluid class="icons-page">
        <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
            <v-col>
                <Table
                        newItemLabel="Update Transfer Money Orders"
                        :filter="filter"
                        :title="title"
                        titleDelete="Refuse Update Order"
                        titleDeleteButton="Refuse"
                        :api="api"
                        :create="create"
                        :del="del"
                        :edit="edit"
                        :headers="headers"
                        @openForm="setForm"
                        @openFormPay="setFormPay"
                        @update_page="(val) => {page = val;}"
                >
                    <template v-slot:[`item.response`]="{ item }">
                        <v-chip
                                class="ma-2"
                                :color="item.response == 'VERIFIED' ? 'green' : 'red'"
                                text-color="white"
                        >
                            {{ item.response == null ? "UNVERIFIED" : item.response }}
                        </v-chip>
                    </template>
                </Table>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog_form" max-width="500px">
            <MoneyUpdate
                    v-if="dialog_form"
                    :fields="fields"
                    :api="api"
                    :page="page"
                    @dialogForm="dialog_form = false"
            ></MoneyUpdate>
        </v-dialog>
    </v-container>
</template>

<script>
    import MoneyUpdate from "../../components/Forms/MoneyUpdate.vue";
    export default {
        components: { MoneyUpdate },
        data() {
            return {
                isNew: true,
                dialog_form: false,
                filter: "UpdateMoney",
                page: 1,
                api: {
                    filter: "get_all_update_order",
                    getAll: "get_all_update_order",
                    edit: "confirm_update_order",
                    delete: "refuse_update_order?id",
                },
                fields: [
                    {
                        name: "notes",
                        key: "notes",
                    },
                ],
                pay: true,
                create: false,
                del: true,
                edit: true,
                title: "transfer money",
                headers: [
                    {
                        text: "#",
                        align: "start",
                        sortable: true,
                        value: "id",
                    },
                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                    },
                    {
                        text: "money id",
                        value: "money.id",
                    },
                    {
                        text: "customer id",
                        value: "money.customer_id",
                    },
                    {
                        text: "beneficiary_no",
                        value: "beneficiary_no",
                    },
                    {
                        text: "beneficiary_name",
                        value: "beneficiary_name",
                    },
                    {
                        text: "status",
                        value: "status",
                    },
                    {
                        text: "beneficiary_no_old",
                        value: "money.beneficiary_no",
                    },
                    {
                        text: "beneficiary_name_old",
                        value: "money.beneficiary_name",
                    },
                    {
                        text: "amount",
                        value: "money.amount",
                    },{
                        text: "amount",
                        value: "money.amount",
                    },
                    {
                        text: "amount_sy",
                        value: "money.amount_sy",
                    },
                    {
                        text: "currency",
                        value: "money.currency",
                    },
                    {
                        text: "status Order",
                        value: "money.status",
                    },
                    {
                        text: "notes",
                        value: "notes",
                    },
                    {
                        text: "response",
                        value: "money.response",
                    },
                    {
                        text: "payment_method",
                        value: "money.payment_method",
                    },
                    {
                        text: "company_name",
                        value: "money.company_name",
                    },
                    {
                        text: "date",
                        value: "created_at",
                    },
                ],
            };
        },
        watch: {
            dialog_form: {
                handler(newVal) {
                    if (!newVal) {
                        this.$store.dispatch("EmptyAmount");
                    }
                },
            },
        },
        methods: {
            setForm(val) {
                let form = {
                    id: null,
                    beneficiary_no: null,
                    beneficiary_name: null,
                    money: null,
                };
                this.$store.dispatch("initForm", form);
                if (val != null) {
                    this.isNew = false;
                    this.$store.dispatch("setForm", val);
                } else {
                    this.isNew = true;
                }
                this.dialog_form = true;
            },

        },
    };
</script>

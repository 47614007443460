<template>
    <v-expansion-panels
            accordion
            flat
            class="primary"
            tile
            v-model="panel"
            :readonly="readonly"
    >
        <v-expansion-panel>
            <v-expansion-panel-header
                    class="white--text font-weight-bold"
                    color="primary"
            >
                <v-icon
                        small
                        color="white"
                        aria-hidden="false"
                        class="me-2 flex-grow-0"
                >
                    mdi-filter
                </v-icon>
                Filter
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                    <v-card-text class="d-flex justify-space-between align-center">
                        <div class="d-flex justify-space-between align-center">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <FormSelect
                                                :items="status_select"
                                                attr="status"
                                                label="Is Done"
                                                @select="(val) => {updateFilter({ key: 'status', value: val.value });}"
                                        >
                                        </FormSelect
                                        >
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <Input
                                                label="money id"
                                                attr="money_id"
                                                @changeValue='(val) => {updateFilter({ key: "money.customer_id", value: val });}'
                                        ></Input
                                        ></v-col>



                                    <v-col cols="12" sm="6" md="6">
                                        <Input
                                                label="beneficiary number"
                                                attr="beneficiary_no"
                                                @changeValue="(val) => {updateFilter({ key: 'beneficiary_no', value: val });}"
                                        ></Input>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <Input
                                                label="beneficiary name"
                                                attr="beneficiary_name"
                                                @changeValue="(val) => {updateFilter({ key: 'beneficiary_name', value: val });}"
                                        ></Input>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>

                    </v-card-text>
                    <v-btn class="ma-2" outlined color="indigo" @click="ResetFilter()">
                        Reset Filter
                    </v-btn>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>
<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        props: {
            api: Object,
            per_page: Number,
            page: Number,
            search: {},
        },
        data() {
            return {
                panel: [0],
                tempData: "",
                DataAfterFiltring: [],
                readonly: false,
                status_select: [
                    {text: "تم التحقق من من قبل الأدمن", value: '1'},
                    {text: "لم يتم التحقق بعد", value: '0'},
                ],

                filter_init_value: {},
            };
        },
        components: {},
        watch: {
            search(val) {
                this.updateFilter(val);
            },
        },
        computed: {
            ...mapGetters(["getFilter", "getContractTypes", "getLocations"]),
            contractTypes() {
                return this.getContractTypes;
            },
            Locations() {
                return this.getLocations;
            },
        },
        methods: {
            ...mapActions([
                "createFilter",
                "initFilter",
                "fetchTableDataFilter",
                "fetchLocations",
            ]),
            updateFilter(val) {
                this.createFilter(val).then(() => {
                    this.fetchTableDataFilter({
                        api: this.api,
                        page: this.page,
                        per_page: this.per_page,
                    });
                });
            },
            ResetFilter() {
                window.location.reload();
            },
        },
        mounted() {
            this.initFilter(this.filter_init_value);
        },
    };
</script>

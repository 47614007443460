<template>
  <v-expansion-panels
    accordion
    flat
    class="primary"
    tile
    v-model="panel"
    :readonly="readonly"
  >
    <v-expansion-panel>
      <v-expansion-panel-header
        class="white--text font-weight-bold"
        color="primary"
      >
        <v-icon
          small
          color="white"
          aria-hidden="false"
          class="me-2 flex-grow-0"
        >
          mdi-filter
        </v-icon>
        Filter
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card class="" color="white">
          <v-card-text class="d-flex justify-space-between align-center">
            <div class="d-flex justify-space-between align-center">
              <v-container>
                <v-row>
                  
                  <v-col cols="12" sm="6" md="6">
                    <Input
                      label="id"
                      attr="id"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'id', value: val });
                        }
                      "
                    ></Input
                  ></v-col>
                  <v-col cols="12" sm="6" md="6">
                    <Input
                      label="first name"
                      attr="first_name"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'first_name', value: val });
                        }
                      "
                    ></Input
                  ></v-col>
                  <v-col cols="12" sm="6" md="6">
                    <Input
                      label="last name"
                      attr="last_name"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'last_name', value: val });
                        }
                      "
                    ></Input
                  ></v-col>
               
                  <!-- <v-col cols="12" sm="6" md="6">
                    <Input
                      label="Code"
                      attr="code"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'code', value: val });
                        }
                      "
                    ></Input
                  ></v-col> -->
                  <v-col cols="12" sm="6" md="6">
                    <Input
                      label="email"
                      attr="email"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'email', value: val });
                        }
                      "
                    ></Input
                  ></v-col>
                 
                  <v-col cols="12" sm="6" md="6">
                    <Input
                      label="vesion"
                      attr="vesion"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'vesion', value: val });
                        }
                      "
                    ></Input
                  ></v-col>

                 

                  <v-col cols="12" sm="6" md="6">
                    <Input
                      label="phone"
                      attr="phone"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'phone', value: val });
                        }
                      "
                    ></Input
                  ></v-col>
                  <v-col cols="12" sm="6" md="6">
                    <Input
                      label="birthday"
                      attr="birthday"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'birthday', value: val });
                        }
                      "
                    ></Input
                  ></v-col>
                  <v-col cols="12" sm="6" md="6">
                    <Input
                      label="country"
                      attr="country"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'country', value: val });
                        }
                      "
                    ></Input
                  ></v-col>
                  <v-col cols="12" sm="6" md="6">
                    <Input
                      label="last seen"
                      attr="updated_at"
                      @changeValue="
                        (val) => {
                          updateFilter({ key: 'updated_at', value: val });
                        }
                      "
                    ></Input
                  ></v-col>
                </v-row>
              </v-container>
            </div>
          </v-card-text>
          <v-btn class="ma-2" outlined color="indigo" @click="ResetFilter()">
            Reset Filter
          </v-btn>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    api: Object,
    per_page: Number,
    page: Number,
    search: {},
  },
  data() {
    return {
      panel: [0],
      tempData: "",
      DataAfterFiltring: [],
      //for callapse
      readonly: false,
      status_select: [
        { text: " قيد معالجة الطلب ", value: "قيد معالجة الطلب" },
        { text: " تمت العملية بنجاح ", value: "تمت العملية بنجاح" },
      ],
      responce_select: [
        { text: "UNVERIFIED", value: null },
        { text: "VERIFIED", value: "VERIFIED" },
      ],
      //
      filter_init_value: {},
    };
  },
  components: {},
  watch: {
    search(val) {
      this.updateFilter(val);
    },
  },
  computed: {
    ...mapGetters(["getFilter", "getContractTypes", "getLocations"]),
    contractTypes() {
      return this.getContractTypes;
    },
    Locations() {
      return this.getLocations;
    },
  },
  methods: {
    ...mapActions([
      "createFilter",
      "initFilter",
      "fetchTableDataFilter",
      "fetchLocations",
    ]),
    updateFilter(val) {
      this.createFilter(val).then(() => {
        this.fetchTableDataFilter({
          api: this.api,
          page: this.page,
          per_page: this.per_page,
        });
      });
    },
    ResetFilter() {
      window.location.reload();
    },
  },
  mounted() {
    this.initFilter(this.filter_init_value);
  },
};
</script>

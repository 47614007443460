<template>
  <v-form @submit.prevent="save">
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-for="(field, i) in fields"
              :key="i"
              cols="12"
              sm="6"
              md="6"
            >
            <Switcher v-if="field.type == 'switch' "
                      :attr="field.name"
                      :label="field.name"
                      :model="form[`${field.key}`]"
                      @switcher="(val) => {
                    form[`${field.key}`] = val.value;
                  }"
                    ></Switcher
                  >
             
              <FormSelect v-else-if="field.type == 'select' "
                      :items="field.items"
                      :attr="field.name"
                      :label="field.name"
                      :model="form[`${field.key}`]"

                      @select="(val) => {
                    form[`${field.key}`] = val.value;
                  }"
                    >
                    </FormSelect
                  >
                  <Input v-else
                :label="field.name"
                type="text"
                required
                :model="form[`${field.key}`]"
                @changeValue="
                  (val) => {
                    form[`${field.key}`] = val;
                  }
                "
              ></Input>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn
    color="blue darken-1"
    text>
    Cancel
   </v-btn> -->
        <div>
          <Button color="blue darken-1" type="submit" label="Save"> </Button>
        </div>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [validationMixin],
  props: {
    id:{
      default:null,
    },
    page:{
      default:1
    },
    api: Object,
    isNew: Boolean,
    fields: Array,
    newItemLabel: String,
  },
  data() {
    return {
      required_error_msgs: {
        required: "this field is required",
      },
    };
  },
  computed: {
    ...mapGetters(["getForm"]),

    form() {
      return this.getForm;
    },
    formTitle() {
      return this.newItemLabel;
    },
  },
  watch: {
    model() {},
  },
  methods: {
    save() {
      let formdata = new FormData();
      for (let f in this.form) {
        formdata.append(f, this.form[f]);
      }
      if (!this.isNew) {
        this.form["_method"]= "PUT";
      }
      this.$store.dispatch("sendFormOrder", {
        api: this.api,
        form: this.form,
        isNew: this.isNew,
        id:this.id,
        page:this.page
      });
      this.$emit("dialogForm", false);
    },
  },
};
</script>

<template>
    <div>
        <v-form @submit.prevent="login">
            <v-col>
                <Input
                        label="Email Address"
                        type="text"
                        :model="form.email"
                        :errorMessages="emailErrors"
                        @changeValue="
            (val) => {
              form.email = val;
              $v.form.email.$touch();
            }
          "
                        required
                ></Input>
                <Input
                        type="password"
                        label="Password"
                        hint="At least 6 characters"
                        required
                        :model="form.password"
                        :errorMessages="passwordErrors"
                        @changeValue="
            (val) => {
              form.password = val;
              $v.form.password.$touch();
            }
          "
                ></Input>
            </v-col>
            <v-col class="d-flex justify-space-between">
                <Button
                        color="blue darken-1"
                        type="submit"
                        label="Login"
                        :isLoad="isLoad"
                >
                </Button>
            </v-col>
        </v-form>
    </div>
</template>
<script>
    import {validationMixin} from "vuelidate";
    import {mapGetters, mapActions} from "vuex";

    import {
        required,
        maxLength,
        minLength,
        email,
        sameAs,
    } from "vuelidate/lib/validators";

    export default {
        mixins: [validationMixin],
        props: {
            api: String,
            page: {
                default: 1
            },
        },
        validations: {
            form: {
                email: {
                    required,
                },
                password: {
                    required,
                    minLength: minLength(1),
                },
            },
        },
        data() {
            return {
                email_error_msgs: {
                    required: "this field is required.",
                },
                password_error_msgs: {
                    minLength: "password shoud be at less 1 character.",
                    required: "this field is required.",
                },
            };
        },
        computed: {
            emailErrors() {
                const errors = [];
                if (!this.$v.form.email.$dirty) return errors;
                !this.$v.form.email.required &&
                errors.push(this.email_error_msgs.required);
                return errors;
            },
            passwordErrors() {
                const errors = [];
                if (!this.$v.form.password.$dirty) return errors;
                !this.$v.form.password.minLength &&
                errors.push(this.password_error_msgs.minLength);
                !this.$v.form.password.required &&
                errors.push(this.password_error_msgs.required);
                return errors;
            },
            form() {
                return this.$store.getters.getUser;
            },
            isLoad() {
                return this.$store.getters.getIsLoad;
            },
        },
        methods: {
            login() {
                console.log(this.form);
                this.$v.form.$touch();
                if (!this.$v.form.$invalid) {
                    let formdata = new FormData();
                    for (let f in this.form) {
                        formdata.append(f, this.form[f]);
                    }

                    this.$store.dispatch("login", {

                        api: this.api,
                        form: formdata,
                    });
                } else {
                    this.$toast.error("please complet all fields");
                }
            },
        },
    };
</script>

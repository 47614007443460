<template>
  <v-container fluid class="icons-page">
    <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
      <v-col>
        <!-- List -->
        <Table
          newItemLabel="All Orders"
          :title="title"
          :api="api"
          :filter="filter"
          :create="create"
          :del="del"
          :edit="edit"
          :headers="headers"
          @openForm="setForm"
          @update_page="
            (val) => {
              page = val;
            }
          "
        ></Table>
        <!-- Form -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isNew: true,
      dialog_form: false,
      page: 1,
      filter: "orders",

      api: {
        getAll: "failByFriends",
        filter: "failByFriends",
        create: "candidates",
        delete: "candidate?candidate_id",
      },
      create: false,
      del: true,
      edit: true,
      title: "Fail Orders",
      fields: [
        {
          name: "الحجم",
          key: "size",
        },
        {
          name: "السعر",
          key: "price",
        },
      ],
      headers: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "actions",
          value: "actions",
          sortable: false,
        },
        {
          text: "first_name",
          value: "first_name",
        },
        {
          text: "last_name",
          value: "last_name",
        },
        {
          text: "customer id",
          value: "customer_id",
        },
        {
          text: "rest",
          value: "rest",
        },
        {
          text: "beneficiary_no",
          value: "beneficiary_no",
        },

        {
          text: "error response",
          value: "error_response",
        },
        {
          text: "status",
          value: "status_order",
        },
       
        {
          text: "type phone",
          value: "type_phone",
        },
        {
          text: "price_id",
          value: "price_id",
        },

        {
          text: "currency",
          value: "currency",
        },
        {
          text: "PayPal Account",
          value: "paypal_name",
        },{
          text: "payment method",
          value: "payment_method",
        },
        {
          text: "profit",
          value: "profit",
        },
        {
          text: "created_at",
          value: "created_at",
        },
      ],
    };
  },
  methods: {
    setForm(val) {
      let form = {
        size: null,
        price: null,
      };
      this.$store.dispatch("initForm", form);
      if (val != null) {
        this.isNew = false;
        this.$store.dispatch("setForm", val);
      } else {
        this.isNew = true;
      }
      this.dialog_form = true;
    },
  },
  //  mounted() {
  //   this.$store.dispatch('initForm', this.form)
  //  }
};
</script>

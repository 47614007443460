<template>
    <v-container fluid class="icons-page">
      <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
        <v-card width="100%"
  elevation="2"
>
<v-card-title>Islamic details</v-card-title>


<v-list  v-if="item">
       <template  >
        <v-list-item
        v-for="key in Object.keys(item)"
        :key="key"
        >
        <v-subheader
        >{{key}}</v-subheader>
        
        {{ item[`${key}`] }}
       
      </v-list-item>
      

        </template>
    </v-list>

</v-card>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default{
    computed:{
      item() {
      return this.$store.getters.getItem;
    }},
    keys(){
      return Object.keys(this.$store.getters.getItem)
    },
    mounted() {
    this.$store.dispatch(
      "fetchItem",
      "bank-service/" + this.$route.params.id
    );
  },   
  }
  
  </script>
  
<template>
    <div>

        <coupon-filter
                :search="search"
                :api="api"
                :per_page="per_page"
                :page="page"
                :headers="headers"
                v-if="filter == 'coupon'"
        ></coupon-filter>

        <OrdersFilter
                :search="search"
                :api="api"
                :per_page="per_page"
                :page="page"
                :headers="headers"
                v-if="filter == 'orders'"
        >

        </OrdersFilter>

        <BankFilter
                :search="search"
                :api="api"
                :per_page="per_page"
                :page="page"
                :headers="headers"
                v-if="filter == 'bank'"
        >

        </BankFilter>
        <BillFilter
                :search="search"
                :api="api"
                :per_page="per_page"
                :page="page"
                :headers="headers"
                v-if="filter == 'bill'"
        >

        </BillFilter>

        <CustomerFilter

                :search="search"
                :api="api"
                :per_page="per_page"
                :page="page"
                :headers="headers"
                v-if="filter == 'customer'"
        >

        </CustomerFilter>

        <money-filter

                :search="search"
                :api="api"
                :per_page="per_page"
                :page="page"
                :headers="headers"
                v-if="filter == 'money'"
        >

        </money-filter>

        <UpdateMoney

                :search="search"
                :api="api"
                :per_page="per_page"
                :page="page"
                :headers="headers"
                v-if="filter == 'UpdateMoney'"
        >

        </UpdateMoney>
    </div>
</template>

<script>
    import CouponFilter from './components/CouponFilter.vue';
    import MoneyFilter from './MoneyFilter.vue';
    import UpdateMoney from './UpdateMoneyFilter.vue';
    import BankFilter from './BankFilter.vue';
    import BillFilter from './BillFilter.vue';

    export default {
        components: {
            CouponFilter,
            MoneyFilter,
            UpdateMoney,
            BankFilter,
            BillFilter
        },
        props: {
            per_page: Number,
            headers: Array,
            page: Number,
            search: {
                default: "",
            },
            api: {
                default: "",
            },
            filter: {
                default: "",
            },
        },
    };
</script>

<template>
  <v-form @submit.prevent="save">
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <Input
                label="Name in Arabic"
                type="text"
                :model="form.name_ar"
                :errorMessages="name_arErrors"
                @changeValue="
                  (val) => {
                    form.name_ar = val;
                    $v.form.name_ar.$touch();
                  }
                "
              ></Input>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <Input
                label="Name in English"
                type="text"
                :model="form.name_en"
                :errorMessages="name_enErrors"
                @changeValue="
                  (val) => {
                    form.name_en = val;
                    $v.form.name_en.$touch();
                  }
                "
              ></Input>
            </v-col>
            <v-col cols="12" sm="6" md="12">
              <Input
                label="Code"
                type="text"
                :model="form.code"
                :errorMessages="codeErrors"
                @changeValue="
                  (val) => {
                    form.code = val;
                    $v.form.code.$touch();
                  }
                "
              ></Input>
            </v-col>
          
            <v-col v-if="passwordChange" cols="12" sm="6" md="6">
              <File
                label="Image"
                type="file"
                :model="form.img"
                :errorMessages="imgErrors"
                @changeValue="
                  (val) => {
                    form.img = val;
                    $v.form.img.$touch();
                  }
                "
              ></File>
            </v-col>
          
            
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      
        <div>
          <Button color="blue darken-1" type="submit" label="Save"> </Button>
        </div>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [validationMixin],
  props: {
    api: Object,
    isNew: Boolean,
    page:{
      default:1
    },
    newItemLabel: {
      default: "عنصر",
    },
  },
  validations: {
    form: {
      name_ar: {
        required,
      },
      name_en: {
        required,
      },
      code: {
        required,
      },
      img: {
        required,
      },
     
    },
  },
  data() {
    return {
      // repeatPassword: "",
      passwordChange: true,
     
      name_ar_error_msgs: {
        required: "This Field is Required.",
      },
      name_en_error_msgs: {
        required: "This Field is Required.",
      },
      code_error_msgs: {
        required: "This Field is Required.",
      },
      img_error_msgs: {
        required: "This Field is Required.",
      },
    
    };
  },
  computed: {
    ...mapGetters(["getForm"]),
    name_arErrors() {
      const errors = [];
      if (!this.$v.form.name_ar.$dirty) return errors;
      !this.$v.form.name_ar.required &&
        errors.push(this.name_ar_error_msgs.required);
      return errors;
    },
    name_enErrors() {
      const errors = [];
      if (!this.$v.form.name_en.$dirty) return errors;
      !this.$v.form.name_en.required &&
        errors.push(this.name_en.required);
      return errors;
    },
    codeErrors() {
      const errors = [];
      if (!this.$v.form.code.$dirty) return errors;
      !this.$v.form.code.required &&
        errors.push(this.code_error_msgs.required);
      return errors;
    },
    imgErrors() {
      const errors = [];
      if (!this.$v.form.img.$dirty) return errors;
      !this.$v.form.img.required &&
        errors.push(this.img_error_msgs.required);
      return errors;
    },
    form() {
      return this.getForm;
    },
    formTitle() {
      return (this.isNew ? " Create " : " Edit ") + this.newItemLabel;
    },
  },
  watch: {
    model() {},
  },
  methods: {
    save() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        let formdata = new FormData();
        for (let f in this.form) {
          formdata.append(f, this.form[f]);
        }
        if (!this.isNew) {
          formdata.append("_method", "PUT");
        }
        this.$store.dispatch("sendForm", {
          api: this.api,
          form: formdata,
          isNew: this.isNew,
          page:this.page

        });
        this.$emit("dialogForm", false);
      } else {
        this.$toast.error("Pleace Fill All Fields");
      }
    },

  },
};
</script>
